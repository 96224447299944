<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <h2>Группы <b-badge class="larger" variant="light">{{ groups.length }}</b-badge></h2>
      </div>
      <div class="col-md-5 text-md-right">
        <b-button class="ml-2" @click="openEditModal(false)" variant="primary">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
          Добавить
        </b-button>
      </div>
    </div>

    <br>

    <div class="card-deck" v-if="groups">
      <div class="card no-border cursor-pointer shadow-sm" v-for="(group, index) in groups" :key="index" @click.prevent="openEditModal(group)">
        <div class="card-body">
          <div class="progress mb-5" style="height: 10px;">
            <div class="progress-bar" :style="{ 'background': group.colour ? group.colour : '#B5AAB8' }" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <h5 class="card-title mb-2">{{ group.title }}</h5>
          <p class="mb-0" v-if="group.schedule.monday && group.schedule.monday.isActive">
            <span v-if="group.schedule.monday.from">Пн {{ group.schedule.monday.from.slice(0, 5) }}</span> <span v-if="group.schedule.monday.to"> - {{ group.schedule.monday.to.slice(0, 5) }}</span>
          </p>
          <p class="mb-0" v-if="group.schedule.tuesday && group.schedule.tuesday.isActive">
            <span v-if="group.schedule.tuesday.from">Вт {{ group.schedule.tuesday.from.slice(0, 5) }}</span> <span v-if="group.schedule.tuesday.to">- {{ group.schedule.tuesday.to.slice(0, 5) }}</span>
          </p>
          <p class="mb-0" v-if="group.schedule.wednesday && group.schedule.wednesday.isActive">
            <span v-if="group.schedule.wednesday.from">Ср {{ group.schedule.wednesday.from.slice(0, 5) }}</span> <span v-if="group.schedule.wednesday.to">- {{ group.schedule.wednesday.to.slice(0, 5) }}</span>
          </p>
          <p class="mb-0" v-if="group.schedule.thursday && group.schedule.thursday.isActive">
            <span v-if="group.schedule.thursday.from">Чт {{ group.schedule.thursday.from.slice(0, 5) }}</span> <span v-if="group.schedule.thursday.to">- {{ group.schedule.thursday.to.slice(0, 5) }}</span>
          </p>
          <p class="mb-0" v-if="group.schedule.friday && group.schedule.friday.isActive">
            <span v-if="group.schedule.friday.from">Пт {{ group.schedule.friday.from.slice(0, 5) }}</span> <span v-if="group.schedule.friday.to">- {{ group.schedule.friday.to.slice(0, 5) }}</span>
          </p>
          <p class="mb-0" v-if="group.schedule.saturday && group.schedule.saturday.isActive">
            <span v-if="group.schedule.saturday.from">Сб {{ group.schedule.saturday.from.slice(0, 5) }}</span> <span v-if="group.schedule.saturday.to">- {{ group.schedule.saturday.to.slice(0, 5) }}</span>
          </p>
          <p class="mb-0" v-if="group.schedule.sunday && group.schedule.sunday.isActive">
            <span v-if="group.schedule.sunday.from">Вс {{ group.schedule.sunday.from.slice(0, 5) }}</span> <span v-if="group.schedule.sunday.to">- {{ group.schedule.sunday.to.slice(0, 5) }}</span>
          </p>
        </div>
      </div>
      <div class="card shadow-sm cursor-pointer no-border" @click="openEditModal(false)">
        <div class="card-body text-center d-flex align-items-center justify-content-center">
          <div class="text-muted">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            <p class="lead text-center mt-1 mb-0">Добавить группу</p>
          </div>
        </div>
      </div>
    </div>

    <hr class="space space-sm">

    <b-overlay :show="show" rounded="sm">
      <div v-if="groups && students && pendingStudents && pendingStudents.length > 0">
        <h1 class="xl">Заявки от новых учеников <b-badge class="larger" variant="light">{{ pendingStudents.length }}</b-badge></h1>
        <table class="table table-borderless mb-5">
          <thead>
            <th>No.</th>
            <th>Имя, фамилия</th>
            <th>Группа</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="(student, index) in pendingStudents" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ student.firstName }} {{ student.lastName }} 
              </td>
              <td><p class="text-muted">Не присвоена</p></td>
              <td>
                <p>
                  <a href="#" class="btn btn-primary btn-sm" @click.prevent="openEditStudentModal(student)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg> 
                    Добавить в группу
                  </a>
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></!--> 
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row" v-if="students">
        <div class="col-md-3">
          <h1 class="xl">Ученики <b-badge class="larger" variant="light">{{ students.length }}</b-badge></h1>
        </div>
        <div class="col-md-4 d-flex justify-content-center align-items-center">
          
        </div>
        <div class="col-md-5 d-flex align-items-start flex-row-reverse">
          <!-- <b-form-select v-model="selectedGroup" :options="groupOptions" value-field="id" options-field="a">
            <b-form-select-option :value="null" disabled>Выберите группу</b-form-select-option>
          </b-form-select> -->
          
          <b-button class="ml-2" @click="openEditStudentModal(false)" variant="primary">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
            Добавить
          </b-button>
          <b-form-select v-model="activeGroup" :options="groupOptions" value-field="id" options-field="a">
            <template v-slot:first>
              <b-form-select-option :value="null">Все группы</b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>

      <table class="table table-borderless" v-if="groups && students && students.length > 0">
        <thead>
          <th>No.</th>
          <th>Имя, фамилия</th>
          <th>Группа</th>
          <th>Спортивный разряд</th>
          <th>Медосмотр</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(student, index) in students" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ student.firstName }} {{ student.lastName }}</td>
            <td>
              <p class="large mb-0">
                <span class="badge small text-white" :style="{ 'background': getGroupFromSlug(student.group)[0].colour ? getGroupFromSlug(student.group)[0].colour : '#B5AAB8' }"> {{ getGroupFromSlug(student.group)[0].title }}</span>
              </p>
            </td>
            <td><p class="text-muted mb-0">Не присвоен</p></td>
            <td><p class="text-muted mb-0">Не пройден</p></td>
            <td>
              <p class="mb-0">
                <a href="#" @click.prevent="openEditStudentModal(student)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="border rounded p-3 py-5 text-center text-muted">
        <h5 class="text-muted">Учеников нет</h5>
        <p class="mb-0">Чтобы добавить ученика, нажмите на кнопку <a href="#" @click.prevent="openEditStudentModal(false)">Добавить</a>.</p>
      </div>
    </b-overlay>

    <group-add :item="editGroup" ref="modal" @update="update"></group-add>
    <student-add :item="editStudent" ref="modalStudent" @update="updateStudent" :testItem="testItem"></student-add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { objectToArraySafe } from '@/services/utils'
import GroupAdd from './Includes/GroupAddModal'
import StudentAdd from './Includes/StudentAddModal'

export default {
  name: 'students',
  components: {
    groupAdd: GroupAdd,
    studentAdd: StudentAdd
  },
  data () {
    return {
      show: true,
      editGroup: null,
      editStudent: null,
      selectedGroup: null,
      activeGroup: null,
      testItem: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      groups: state => state.coaches.groups,
      groupOptions: state => objectToArraySafe(state.coaches.groups),
      students: state => state.coaches.students,
      pendingStudents: state => state.auth.user.pendingStudents
    })
  },
  methods: {
    getGroupFromSlug (groupSlug) {
      if (this.groups) {
        return this.groups.filter((group) => {
          return group.slug === groupSlug
        })
      }
    },
    getGroupIndexFromSlug (groupSlug) {
      const groupBySlug = (group) => group.slug === groupSlug
      return this.groups.findIndex(groupBySlug)
    },
    openEditModal (item) {
      if (item) {
        this.editGroup = item
        setTimeout(() => {
          this.$refs.modal.showModal()
        }, 100)
      } else {
        this.editGroup = null
        setTimeout(() => {
          this.$refs.modal.showModal()
        }, 100)
      }
    },
    update () {
      this.$store.dispatch('GET_GROUPS')
      this.$store.dispatch('GET_USER')
      this.$refs.modal.hideModal()
    },
    openEditStudentModal (item) {
      if (item) {
        this.editStudent = item
        this.testItem = true
        setTimeout(() => {
          this.$refs.modalStudent.showModal()
        }, 100)
      } else {
        this.editStudent = null
        setTimeout(() => {
          this.$refs.modalStudent.showModal()
        }, 100)
      }
    },
    updateStudent () {
      this.$store.dispatch('GET_USER').then(() => {
        this.$store.dispatch('GET_COACH_STUDENTS', this.user.students)
      })
      this.$refs.modalStudent.hideModal()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.show = false
    if (this.user.pendingStudents) {
      this.$store.dispatch('GET_PENDING_STUDENTS', this.user.pendingStudents)
    }
    if (this.user.students) {
      this.$store.dispatch('GET_COACH_STUDENTS', this.user.students)
    }
  },
  created () {
    this.$store.dispatch('GET_GROUPS')
    this.$store.dispatch('GET_USER')
  },
  watch: {
    activeGroup (value) {
      this.show = true
      if (value) {
        this.$store.dispatch('GET_STUDENTS_BY_GROUP', { students: this.user.students, group: value }).then(() => {
          this.show = false
        })
      } else {
        this.$store.dispatch('GET_USER').then(() => {
          this.$store.dispatch('GET_COACH_STUDENTS', this.user.students).then(() => {
            this.show = false
          })
        })
      }
    }
  }
}
</script>