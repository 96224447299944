<template>
  <b-modal 
    id="modal-1"
    ref="modal"
    :title="title"
    @show="toggleModal"
    @hidden="resetModal"
    @ok="handleOk"
    ok-title="Добавить город"
    cancel-title="Отмена"
    cancel-variant="light"
    footer-class="justify-content-between"
  >
    <pre>{{ city }}</pre>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Название города" label-for="title">
        <b-form-input
          id="title"
          v-model="city.title"
          required
          placeholder=""
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Страна" label-for="parent">
        <b-form-select v-model="city.country" class="mb-3">
          <option :value="null">Выберите страну</option>
          <option v-for="option in countries" :value="option.id" :key="option.id">{{ option.text }}</option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Регион" label-for="region">
        <b-form-select v-model="city.region" class="mb-3">
          <option :value="null">Выберите регион</option>
          <option v-for="option in regions" :value="option.id" :key="option.id">{{ option.text }}</option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Статус" label-for="status">
        <b-form-select v-model="city.isActive" :options="statusOptions" class="mb-3"></b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { slugify, objectToArraySafe } from '@/services/utils'

export default {
  props: {
    item: [Object]
  },
  data () {
    return {
      city: {
        title: '',
        slug: '',
        createdAt: '',
        isActive: true,
        country: '',
        region: ''
      }
    }
  },
  computed: {
    ...mapState({
      statusOptions: state => state.auth.statusOptions
    }),
    countries () {
      return objectToArraySafe(this.$store.getters.getCountries)
    },
    regions () {
      return objectToArraySafe(this.$store.getters.getRegions)
    },
    slug () {
      return slugify(this.city.title)
    },
    title () {
      if (this.item) {
        return 'Редактировать город'
      } else {
        return 'Добавить город'
      }
    }
  },
  methods: {
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid ? 'valid' : 'invalid'
      return valid
    },
    handleSubmit () {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return
      // }
      const { city } = this
      if (this.item) {
        this.$store.dispatch('UPDATE_CITY', city).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      } else {
        this.city.slug = this.slug
        this.city.createdAt = this.$moment().format()
        this.$store.dispatch('CREATE_CITY', { city }).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      }
    },
    resetModal () {
      this.city.title = ''
      this.city.createdAt = ''
      this.city.slug = ''
      this.city.isActive = true
      this.city.country = ''
      this.city.region = ''
    },
    showModal () {
      this.$refs['modal'].show()
    },
    hideModal () {
      this.$refs['modal'].hide()
    },
    toggleModal () {
      this.resetModal()
      if (this.item) {
        this.city.title = this.item.title
        this.city.createdAt = this.item.createdAt
        this.city.isActive = this.item.isActive
        this.city.slug = this.item.slug
        this.city.country = this.item.country
        this.city.region = this.item.region
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
  }
}
</script>