<template>
  <div class="container">
    <h1 class="xl">Пользовательское соглашение</h1>

    <p>Настоящее Пользовательское соглашение (далее - «Соглашение») определяет условия
    и правила использования Материалов и Сервисов Сайта KyokushinProfi.com (далее – «Сайт»), а
    также устанавливает права и обязанности его Пользователей и Администрации Сайта.
    Соглашение распространяется также на отношения, связанные с третьими лицами, не
    являющимися Пользователями Сайта, но чьи права и интересы могут быть затронуты в
    результате действий Пользователей Сайта.</p>

    <h3>1. Общие условия</h3>

    <p>1.1. Настоящее Соглашение является юридически обязательным соглашением между
    Пользователем и Администрацией Сайта, предметом которого является предоставление
    Администрацией Сайта Пользователю права использования Сайта и его Сервисов.</p>

    <p>1.2. Настоящее Соглашение является публичной офертой в соответствии со ст. 437
    Гражданского кодекса РФ. Пользователь обязан полностью ознакомиться с настоящим
    Соглашением до момента регистрации на Сайте. Регистрация Пользователя на Сайте
    означает полное и безоговорочное принятие Пользователем условий настоящего
    Соглашения и обязанности их соблюдения в соответствии со ст. 438 Гражданского
    кодекса РФ. Получая доступ к Материалам и Сервисам Сайта, Пользователь считается
    присоединившимся к настоящему Соглашению.</p>

    <h3>2. Статус Администрации Сайта</h3>

    <p>2.1. Под Администрацией Сайта в настоящем Соглашении и иных специальных
    документах, размещенных на Сайте, понимается Международный союз «Киокушин Профи».</p>

    <p>2.2. Обращения, предложения и претензии физических и юридических лиц к
    Администрации Сайта в связи с условиями настоящего Соглашения, вопросами
    по функционированию Сайта, нарушениями прав и интересов третьих лиц при его
    использовании, а также для запросов уполномоченных законодательством РФ лиц могут
    быть направлены на адрес hello@kyokshinprofi.com.</p>

    <p>2.3. Право на использование фирменного наименования, товарных знаков, доменных
    имен и иных отличительных знаков Администрации Сайта может быть предоставлено
    исключительно по письменному соглашению с Администрацией Сайта.</p>

    <h3>3. Статус Пользователя и регистрация на Сайте</h3>

    <p>3.1. Пользователем Сайта является физическое лицо, зарегистрированное на Сайте в
    соответствии с установленным настоящим Соглашением порядком, и достигшее
    возраста 18 лет.</p>

    <p>3.2. Регистрация Пользователя на Сайте является бесплатной и добровольной.</p>

    <p>3.3. Количество и разновидности Сервисов, право использования которых
    предоставляется Пользователю, зависит от регистрации, которую проходит
    Пользователь.</p>

    <p>3.4. При регистрации на Сайте Пользователь обязан предоставить Администрации
    Сайта необходимую достоверную и актуальную информацию для создания своей
    Учетной записи и Личного кабинета. Пользователя, включая уникальные для
    каждого Пользователя логин (адрес электронной почты) и пароль доступа к Сайту,
    а также фамилию и имя, либо избранный Пользователем «никнейм».</p>

    <p>3.5. После предоставления информации, указанной в п. 3.4. настоящего Соглашения,
    Пользователю необходимо пройти процедуру подтверждения регистрации
    на Сайте путем распознания автоматизированного теста, предназначенного
    для различия компьютеров и людей («капчи») и подтверждения регистрации
    путем активации Учетной записи через ссылку в сообщении, отправленном
    Администрацией Сайта на электронную почту Пользователя. В случае верного
    последовательного выполнения данных регистрационных действий на Сайте
    создается Учетная запись Пользователя. Пользователь вправе зарегистрировать на
    Сайте не более одной Учетной записи.</p>

    <p>3.6. Для получения максимальной регистрации и появления возможности оставлять
    комментарии, голосовать, вести блоги на сайте и т.п., Пользователю необходимо
    пройти процедуру дополнительной регистрации на Сайте путем заполнения
    регистрационной формы, предложенной Администрацией с предоставлением
    дополнительных достоверных сведений о себе. После заполнения дополнительной
    регистрационной формы Пользователю необходимо пройти процедуру
    подтверждения регистрации на Сайте путем введения в соответствующее
    диалоговое окно на Сайте кода, полученного Пользователем в виде sms-сообщения
    от Администрации Сайта на мобильный телефон Пользователя. В случае
    верного последовательного выполнения данных регистрационных действий
    Пользователь приобретает статус максимальной регистрации и получает доступ к
    дополнительным сервисам Сайта.</p>

    <p>3.7. При прохождении процедуры регистрации Пользователь также обязан
    ознакомиться с Правилами ведения блога. Завершение регистрации Пользователя
    возможно только при полном и безоговорочном принятии Пользователем Правил.</p>

    <p>3.8. На Пользователя, распространяются все условия настоящего Соглашения.</p>

    <p>3.9. При регистрации каждый Пользователь соглашается с условиями настоящего
    Соглашения и принимает на себя указанные в нем права и обязанности, связанные
    с использованием и функционированием Сайта. После успешной регистрации
    Пользователя на Сайте Администрация принимает на себя права и обязанности
    перед Пользователем, указанные в настоящем Соглашении.</p>

    <p>3.10. Принимая условия настоящего Соглашения путем регистрации на Сайте,
    Пользователь подтверждает свое согласие на обработку Администрацией Сайта его
    персональных данных, предоставленных при регистрации, а также размещаемых
    Пользователем добровольно в своем Личном кабинете и на других Сервисах Сайта.
    Обработка персональных данных Пользователя осуществляется Администрацией
    Сайта в соответствии с законодательством Российской Федерации.</p>

    <p>3.11. Администрация Сайта принимает все необходимые меры для защиты
    персональных данных Пользователя от неправомерного доступа, изменения,
    раскрытия или уничтожения. Администрация Сайта вправе использовать
    предоставленную Пользователем информацию, в том числе персональные данные,
    в целях обеспечения соблюдения требований действующего законодательства
    Российской Федерации (в том числе в целях предупреждения и/или пресечения
    незаконных и/или противоправных действий Пользователей). Раскрытие
    предоставленной Пользователем информации может быть произведено лишь
    в соответствии с действующим законодательством Российской Федерации по
    требованию суда, правоохранительных органов, а равно в иных предусмотренных
    законодательством Российской Федерации случаях.</p>

    <p>3.12. Пользователь несет ответственность за достоверность, актуальность, полноту
    и соответствие законодательству Российской Федерации предоставленной при
    регистрации информации и ее чистоту от претензий третьих лиц.</p>

    <p>3.13. Выбранные Пользователем при регистрации логин и пароль являются
    необходимой и достаточной информацией для доступа Пользователя в Личный
    кабинет на Сайте. Пользователь не имеет права передавать свои логин и пароль
    третьим лицам, несет полную ответственность за их сохранность, самостоятельно
    выбирая способ их хранения.</p>

    <p>3.14. Если Пользователем не доказано обратное, любые действия, совершенные с
    использованием его логина и пароля, считаются совершенными соответствующим
    Пользователем. В случае несанкционированного доступа к логину и паролю и/или
    Учетной записи и Личному кабинету Пользователя, или распространения логина и
    пароля Пользователь обязан незамедлительно сообщить об этом Администрации
    Сайта. Администрация Сайта вправе, но не обязана заблокировать доступ к
    персональной странице Пользователя до разрешения спорной ситуации.</p>

    <h3>4. Права и обязанности Пользователя</h3>

    <p>4.1. Пользователь имеет право использовать Сервисы только с учетом условий и
    ограничений, предусмотренных настоящим Соглашением. Право размещения Материалов
    (применительно к настоящему Соглашения под Материалами понимаются текстовые,
    графические, фото-, аудио-, видео- и иные Материалы. Совокупность Материалов на
    Сайте, а также на его отдельных Сервисах именуется Контентом) предоставляется
    только при успешном прохождении авторизации.</p>

    <p>4.2. <strong>При пользовании Сервисами Сайта Пользователь обязан:</strong></p>

    <p>4.2.1. соблюдать положения действующего законодательства Российской Федерации,
    настоящего Соглашения и иных специальных документов Администрации Сайта;</p>

    <p>4.2.2. предоставлять при регистрации достоверные, точные и полные данные о себе по
    вопросам, предлагаемым в Регистрационной Форме и поддерживать их в актуальном
    состоянии;</p>

    <p>4.2.3. информировать Администрацию Сайта о несанкционированном доступе к Личному
    кабинету Пользователя и/или о несанкционированном доступе и/или использовании
    пароля и логина Пользователя;</p>

    <p>4.2.4. не предоставлять доступ другим Пользователям к собственной Учетной записи
    или к отдельной содержащейся на ней информации в случае, если это может привести
    к нарушению законодательства Российской Федерации и/или настоящего Соглашения,
    специальных документов Администрации Сайта. Всю ответственность за действия,
    совершенные с использованием Логина и Пароля Пользователя, в том числе за
    размещенные под Логином Пользователя Материалы, несет Пользователь;</p>

    <p>4.2.5. не размещать на Сайте информацию и объекты (включая ссылки на них), которые
    могут нарушать законодательство РФ, а также права и интересы других лиц;</p>

    <p>4.2.6. не размещать на Сайте Материалы, автором которых он не является или в
    отношении которых он не обладает правами использования в объеме, достаточном для
    исполнения условий Соглашения;</p>

    <p>4.2.7. перед размещением информации и объектов (в том числе графических изображений,
    текстовых, фото-, аудио- и видеоматериалов, правообладателем которых Пользователь
    не является) предварительно оценивать законность их размещения. В случае наличия
    сомнений в отношении законности осуществления тех или иных действий, в том числе,
    по размещению информации или предоставлению доступа, Администрация Сайта
    рекомендует воздержаться от осуществления последних;</p>

    <p>4.2.8. не совершать действий, направленных на несанкционированный доступ к
    программному обеспечению Сайта;</p>

    <p>4.2.9. не осуществлять сбор или хранение персональных данных других Пользователей и
    не использовать их в коммерческих целях;</p>

    <p>4.2.10. не заниматься какой-либо деятельностью, приносящей доход Пользователю,
    от имени Сайта, не выдавать себя за сотрудника Администрации Сайта, без наличия
    соответствующих правоотношений между Администрацией Сайта и Пользователем.</p>

    <p>4.3. Пользователь вправе разместить на Сайте для ознакомления неограниченного круга
    лиц свои персональные данные. Размещая свои персональные данные, за исключением
    информации предоставляемой Администрации Сайта в процессе регистрации,
    Пользователь сам определяет, в каком объеме сделать публичной информацию о
    себе и отдает себе отчет в том, что с момента обнародования к его персональным
    данным получат доступ не только сотрудники Администрации Сайта, но и третьи лица.
    Ответственность за последствия такого размещения несет сам Пользователь.</p>

    <p>4.4. <strong>Пользователь не имеет права размещать на Сайте Материалы, которые:</strong></p>

    <p>4.4.1. содержат сведения, составляющие государственную или иную специально
    охраняемую законом тайну;</p>

    <p>4.4.2. содержат призыв к захвату власти, насильственному изменению конституционного
    строя и целостности государства;</p>

    <p>4.4.3. направлены на возбуждение ненависти либо вражды, а также на унижение
    достоинства человека либо группы лиц по признакам пола, расы, национальности, языка,
    происхождения, отношения к религии, а равно принадлежности к какой-либо социальной
    группе, а также иные материалы экстремисткой направленности;</p>

    <p>4.4.4. содержат пропаганду и демонстрируют нацистскую атрибутику или символику либо
    атрибутику или символику, сходную с нацистской до степени смешения;</p>

    <p>4.4.5. содержат публичные призывы к осуществлению террористической деятельности
    или публично оправдывают терроризм, а также пропагандируют войну, порнографию,
    культ насилия и жестокости;</p>

    <p>4.4.6. содержат описание средств и способов насилия и суицида, любое подстрекательство
    к их совершению;</p>

    <p>4.4.7. содержат информацию об общественном объединении, религиозной или иной
    организации, включенных в опубликованный Министерством юстиции РФ перечень
    организаций, которые ликвидированы, либо деятельность которых в судебном порядке
    запрещена (в соответствии с Федеральным законом "О противодействии экстремистской
    деятельности"), без прямого указания на то, что соответствующее общественное
    объединение или иная организация ликвидированы или их деятельность запрещена;</p>

    <p>4.4.8. содержат сведения, порочащие честь, достоинство или деловую репутацию
    других лиц, носящие оскорбительный, клеветнический характер, а также нарушающие
    неприкосновенность частной жизни других лиц, в том числе путем разглашения их
    персональных данных;</p>

    <p>4.4.9. содержат изображение других лиц, без их согласия на обнародование:
    (Распространение изображения гражданина без его согласия допускается только (1) в
    государственных, общественных или иных публичных интересах; (2) если изображение
    гражданина получено при съемке, которая проводилась в местах, открытых для
    свободного посещения, или на публичных мероприятиях, за исключением случаев, когда
    такое изображение является основным объектом использования (портретный снимок); (3)
    если гражданин позировал за плату);</p>

    <p>4.4.10. содержат ненормативную лексику, являются непристойными, содержат
    порнографические изображения, тексты или сцены сексуального характера, в том числе с
    участием несовершеннолетних;</p>

    <p>4.4.11. содержат вредоносный программный код (ссылки), способный повредить
    программному обеспечению, а также описание способов получения неправомерного
    доступа к компьютерной информации или ссылки на место его хранения в Сети;</p>

    <p>4.4.12. содержат сведения о способах, методах разработки, изготовления и использования,
    местах приобретения наркотических средств, психотропных веществ и иных запрещенных
    веществ, а также пропагандируют их использование;</p>

    <p>4.4.13. нарушают авторские права других лиц;</p>

    <p>4.4.14. содержат сообщения рекламного характера, «спам» и другие материалы,
    навязывающих товары и услуги;</p>

    <p>4.4.15. носят мошеннический характер;</p>

    <p>4.4.16. содержат иную информацию, нарушающую законные права и интересы граждан и
    юридических лиц или требования законодательства Российской Федерации.</p>

    <p>4.5. Пользователь несет личную ответственность за любую информацию, которую
    размещает на Сайте, сообщает другим Пользователям, а также за любые взаимодействия с
    другими Пользователями, осуществляемые на свой риск.</p>

    <h3>5. Права и обязанности Администрации Сайта</h3>

    <p>5.1. В отношении функционирования и развития Сайта Администрация руководствуется
    законодательством Российской Федерации, настоящим Соглашением и иными
    специальными документами, которые разработаны или могут быть разработаны и
    приняты Администрацией Сайта в целях регламентации предоставления Пользователям
    отдельных Сервисов Сайта.</p>

    <p>5.2. Администрация Сайта обязуется предоставить Пользователю право использования
    Сервисов Сайта после успешного завершения Регистрации, а также поддерживать
    Сервисы в работоспособном состоянии. Исполнение Администрацией Сайта обязанности
    по предоставлению права использования Сервисов подтверждается фактом размещения на
    Сайте Материалов Пользователя.</p>

    <p>5.3. В случае, если у Администрации возникают основания предполагать, что
    предоставленная Пользователем информация о себе не соответствует действительности,
    его доступ к Cайту может быть временно ограничен. В случае установления факта
    предоставления ложной информации на момент регистрации регистрация Пользователя
    может быть аннулирована, учетная запись удалена.</p>

    <p>5.3. Администрация Сайта вправе контролировать размещение Пользователем
    Материалов. В случае нарушения Пользователем обязанностей, предусмотренных
    разделом 4 настоящего Соглашения, Администрация Сайта вправе удалить любой
    Материал с Сайта без предварительного уведомления Пользователя и объяснения причин.</p>

    <p>5.4. Администрация Сайта также вправе ограничивать право Пользователя использовать
    Сервисы в случае неоднократного или злостного нарушения таким Пользователем
    условий настоящего Соглашения.</p>

    <p>5.5. В случае нарушения Пользователем интеллектуальных прав третьих лиц,
    Администрация Сайта вправе по требованию таких лиц и при наличии соответствующих
    правовых (документально подтвержденных) оснований удалить соответствующие
    материалы и принять дальнейшие меры вплоть до аннулирования Регистрации и
    удаления Учетной записи Пользователя.</p>

    <p>5.6. Администрация Сайта вправе размещать на любой странице Сайта, в том числе на
    одной странице с Материалами Пользователя, рекламные и информационные сообщения
    без получения на это согласия Пользователя. Пользователь не несет ответственность за
    содержание иных Материалов (рекламного, информационного характера), размещенных
    Администрацией Сайта по своему усмотрению.</p>

    <h3>6. Интеллектуальные права</h3>

    <p>6.1. Все интеллектуальные права на Сайт и его отдельные компоненты, базу данных,
    коды, элементы дизайна, шрифты, логотипы, товарные знаки, текст, графические
    изображения, иллюстрации, фото-, видео- и иные объекты являются объектами
    исключительных прав Администрации Сайта и не могут быть использованы без согласия
    правообладателя.</p>

    <p>6.2. Пользователь, размещая на Сайте текстовые, графические, фото-, аудио-,
    видео- и иные Материалы, автором или правообладателем которых он является
    на законных основаниях, предоставляет другим пользователям неисключительное
    право на их использование путем просмотра, воспроизведения (в том числе
    копирования, цитирования), переработку и иные права исключительно с целью личного
    некоммерческого использования, кроме случаев, когда такое использование причиняет
    или может причинить вред охраняемым законом интересам правообладателя.</p>

    <p>6.3. Пользователь несет личную ответственность за любые Материалы или иную
    информацию, которую он размещает на Сайте. За исключением своего собственного
    Контента, Пользователь не вправе размещать на Сайте KyokushinProfi.com Контент из
    других источников, Интернет-сайтов, баз данных, использовать прочие результаты
    интеллектуальной деятельности третьих лиц при отсутствии явным образом выраженного
    согласия правообладателей на такое воспроизведение без получения дополнительного
    согласия автора или правообладателя. При этом личное некоммерческое использование
    Пользователем Контента третьих лиц допускается исключительно при условии
    сохранения всех знаков авторства (копирайтов) или других уведомлений об авторстве,
    сохранения имени автора в неизменном виде, сохранения произведения в неизменном
    виде и при наличии требования автора оформления активной прямой ссылки на
    первоисточник.</p>

    <p>6.4. Размещая свои Материалы на Сайте, Пользователь передает Администрации Сайта
    право делать их копии с целью упорядочения и облегчения публикации и хранения
    пользовательского Контента на Сайте.</p>

    <p>6.5. Пользователь, размещая свои Материалы в любой части Сайта, автоматически
    безвозмездно предоставляет Администрации неисключительное право на их
    использование в любой форме и любым способом, в том числе путем воспроизведения,
    копирования, публичного исполнения, переработки, перевода на другие языки и
    распространения для целей Сайта или в связи с ними, в том числе для его популяризации.
    Для указанных целей Администрация может изготавливать производные произведения
    или вставлять Материалы Пользователя в качестве составных частей в иные
    информационные материалы, совершать иные действия, служащие достижению
    указанных целей. Право использовать Материалы предоставляется на территории всего
    мира, на безвозмездной основе без выплаты Пользователю какого-либо вознаграждения.</p>

    <p>6.7. При использовании созданного Пользователем Контента Администрация Сайта
    соблюдает неимущественные права Пользователя как автора, в том числе обязуется в
    каждом случае использования Контента указывать имя Пользователя, сообщенное им при
    регистрации на Сайте.</p>

    <h3>7. Ответственность Сторон</h3>

    <p>7.1. Администрация Сайта не несет ответственности перед Пользователем за потерю
    Материалов, произошедшую не по ее вине.</p>

    <p>7.2. Администрация Сайта не несет ответственности за сбои в работе или
    неработоспособность Сервисов, если указанные сбои или неработоспособность стали
    следствием:</p>

    <p>7.2.1. нарушений в работе информационно-телекоммуникационных или энергетических
    сетей;</p>

    <p>7.2.2. действий третьих лиц, направленных на несанкционированный доступ к
    программному обеспечению Сайта и/или на выведение указанного программного
    обеспечения из строя.</p>

    <p>7.3. Пользователь несет ответственность в полной мере за содержание опубликованных
    им Материалов в соответствии с требованиями гражданского, уголовного
    законодательства, а также законодательства об административных правонарушениях.</p>

    <p>7.4. Пользователь несет ответственность за ущерб, причиненный Администрации Сайта
    в связи с несоблюдением им обязанностей, предусмотренных разделом 4 настоящего
    Соглашения.</p>

    <p>7.5. Ни Пользователь, ни Администрация Сайта не несут ответственности за полное или
    частичное неисполнение своих обязательств по Соглашению, если такое неисполнение
    явилось следствием обстоятельств непреодолимой силы, возникших после заключения
    Соглашения в результате событий чрезвычайного характера, которые они не могли ни
    предвидеть, ни предотвратить разумными мерами (форс-мажор). К таким событиям
    чрезвычайного характера относятся: наводнение, пожар, землетрясение, оседание почвы
    и другие стихийные бедствия; война или военные действия, эмбарго, блокады, действия
    властей, препятствующие исполнению Соглашения.</p>

    <h3>8. Разрешение споров</h3>

    <p>8.1. Соглашение составлено в соответствии с законодательством Российской Федерации.
    Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в
    соответствии с законодательством Российской Федерации.</p>

    <p>8.2. В случае возникновения любых споров или разногласий, связанных с исполнением
    настоящего Соглашения, Пользователь и Администрация Сайта приложат все усилия для
    их разрешения путем проведения переговоров. В случае, если споры не будут разрешены
    путем переговоров, то они подлежат рассмотрению в суде по месту нахождения
    Администрации Сайта.</p>

    <p>8.3. Если у Пользователя есть обоснованные претензии, касающиеся возможного
    неправомерного использования на Сайте интеллектуальной собственности, такой
    Пользователь обязуется уведомить об этом Администрацию Сайта путем направления
    электронного сообщения на адрес электронной почты hello@kyokushinprofi.com обязательным
    вложением электронных копий документов, подтверждающих указанное нарушение.</p>

    <h3>9. Прочие условия</h3>

    <p>9.1. Администрация Сайта и Пользователь признают юридическую силу настоящего
    Соглашения и считают процедуру подтверждения регистрации и авторизации на
    Сайте через введение логина и пароля для доступа к аккаунту на Сайте аналогом
    собственноручной подписи настоящего Соглашения.</p>

    <p>9.2. Соглашение вступает в силу с момента принятия Пользователем его условий, что
    подтверждается фактом проставления отметки (клика) в специальном окне о принятии
    условий Соглашения, и считается заключенным на неопределенный срок.</p>

    <p>9.3. Администрация Сайта вправе в одностороннем порядке вносить в Соглашение
    изменения, которые вступают в силу с момента публикации на Сайте. При несогласии
    Пользователя с внесенными изменениями он в одностороннем порядке вправе
    отказаться от доступа к Сайту, прекратить использование Материалов и Сервисов
    Сайта. Продолжение использования Сайта Пользователем после внесения изменений
    в настоящее Соглашение означает принятие и согласие Пользователя с такими
    изменениями.</p>

    <p>9.4. Если по тем или иным причинам одно или несколько положений настоящего
    Соглашения будут признаны недействительными или не имеющими юридической силы,
    это не влечет недействительности или неисполнимости иных положений Соглашения.</p>

    <p>9.5. Пользователь понимает и согласен с тем, что размещенные на Сайте Материалы могут
    быть индексированы поисковыми системами и хранится в памяти таких систем. В случае
    удаления Материала с Сайта, произошедшего по любым причинам, Администрация
    Сайта не будет иметь возможности удалить проиндексированный Материал и не несет
    ответственности за связанные с этим последствия.</p>
  </div>
</template>

<script>
export default {
  name: 'Terms',
  data () {
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>