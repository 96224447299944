import firebase from 'firebase/app'
const db = firebase.firestore()

const state = {
  coaches: [],
  coach: null,
  groups: []
}

const actions = {
  GET_COACHES_BY_CITY ({ commit }, city) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('users').where('isCoach', '==', true).where('city', '==', city)
      let unsub = ref.onSnapshot(snapshot => {
        let coaches = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          coaches.push(log)
        })
        commit('SET_LOADED_COACHES', coaches)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  },
  GET_STUDENT_COACH ({ commit }, coachEmail) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('users').where('isCoach', '==', true).where('email', '==', coachEmail)
      let unsub = ref.onSnapshot(snapshot => {
        let coaches = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          coaches.push(log)
        })
        commit('SET_LOADED_COACH', coaches[0])
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  },
  GET_STUDENT_GROUPS ({ commit }, { coachEmail, groupSlug }) {
    return new Promise((resolve, reject) => {
      let allGroups = []
      db.collection('users')
        .doc(coachEmail)
        .collection('groups')
        .doc(groupSlug)
        .get()
        .then((doc) => {
          if (doc) {
            allGroups.push(doc.data())
            commit('SET_LOADED_GROUPS', allGroups)
          }
        })
    })
  },
  ADD_STUDENT_TO_COACH ({ commit }, { coachEmail, studentId }) {
    return new Promise((resolve, reject) => {
      db.collection('users').doc(coachEmail).update({
        pendingStudents: firebase.firestore.FieldValue.arrayUnion(studentId),
        coach: coachEmail
      }).then(snapshot => {
        resolve(snapshot)
      })
    })
  }
}

const getters = {
}

const mutations = {
  SET_LOADED_COACHES (state, payload) {
    state.coaches = payload
  },
  SET_LOADED_COACH (state, payload) {
    state.coach = payload
  },
  SET_LOADED_GROUPS (state, payload) {
    state.groups = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
