<template>
  <div class="d-flex justify-content-start align-items-center">
    <div @click="openEditModal()">
      <b-avatar size="10rem" class="mr-2" variant="danger" src="/static/images/avatar.svg"></b-avatar>
    </div>
    <div class="w-100 ml-md-3">
      <div class="d-flex justify-content-between">
        <div>
          <h2>{{ user.firstName }} {{ user.lastName }}</h2>
          <p class="lead text-secondary mb-4" v-if="user.city && user.country">
            <span v-if="user.city">{{ user.city | humanizeCity(cities) }} {{ user.city | getCityRegion(cities, regions) }}</span> <span v-if="user.country">{{ user.country | humanizeCountry(countries) }}</span>
          </p>
          <p class="lead text-secondary mb-4" v-else>
            Адрес не указан
          </p>
        </div>
        <div>
          <a href="#" @click.prevent="openEditModal()" class="btn btn-light">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg> 
              Редактировать
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3" v-if="user.belt">
          <p class="mb-1">Данные</p>
          <p><b-badge class="fz-md normal" variant="light">{{ user.belt }}</b-badge></p>
        </div>
        <div class="col-md-3" v-if="groups && groups.length > 0">
          <p class="mb-1">Группа</p>
          <p><b-badge class="fz-md normal" variant="light" v-for="group in groups" :key="group.id">{{ group.title }}</b-badge></p>
        </div>
        <!-- <div class="col-md-3">
          <p class="mb-1">Спортивный разряд</p>
          <p><b-badge class="fz-md normal" variant="light">01.09</b-badge></p>
        </div>
        <div class="col-md-3">
          <p class="mb-1">Здоровье</p>
          <p><b-badge class="fz-md normal" variant="light">Здоров</b-badge></p>
        </div> -->
      </div>
    </div>

    <profile-edit :item="user" ref="modal" @update="update"></profile-edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProfileEdit from '../../Student/Includes/ProfileEditModal'

export default {
  props: {
    user: Object
  },
  components: {
    profileEdit: ProfileEdit
  },
  data () {
    return {
      show: true,
      year: this.$moment().year(),
      quarter: this.$moment().quarter()
    }
  },
  computed: {
    ...mapState({
      cities: state => state.locations.cities,
      regions: state => state.locations.regions,
      countries: state => state.locations.countries,
      isLoading: state => state.auth.loading,
      students: state => state.coaches.students,
      journal: state => state.coaches.journal,
      groups: state => state.students.groups
    }),
    activeStudents () {
      if (this.students) {
        return this.students.filter((student) => {
          return !student.isActive
        })
      }
    },
    activeStudentsFromJournal () {
      let students = []
      if (this.journal) {
        for (let [month] of Object.entries(this.journal)) {
          for (let [day] of Object.entries(this.journal[month])) {
            if (this.journal[month][day].students && this.journal[month][day].students.length > 0) {
              this.journal[month][day].students.forEach((student) => {
                students.push(student)
              })
            }
          }
        }
        return [ ...new Set(students) ]
      }
    },
    activeStudentsCurrentQuarter () {
      let students = []
      let months = []
      if (this.journal) {
        if (this.quarter === 1) {
          months = ['12', '01', '02']
        } else if (this.quarter === 2) {
          months = ['03', '04', '05']
        } else if (this.quarter === 3) {
          months = ['06', '07', '08']
        } else {
          months = ['09', '10', '11']
        }
        for (let [month] of Object.entries(this.journal)) {
          if (months.includes(month)) {
            for (let [day] of Object.entries(this.journal[month])) {
              if (this.journal[month][day].students && this.journal[month][day].students.length > 0) {
                this.journal[month][day].students.forEach((student) => {
                  students.push(student)
                })
              }
            }
          }
        }
        return [ ...new Set(students) ]
      }
    },
    quarterStudentsGoal () {
      let totalStudentsGoal = null
      if (this.quarter === 1) {
        totalStudentsGoal = this.user.goalStudentsQuarterOne
      } else if (this.quarter === 2) {
        totalStudentsGoal = this.user.goalStudentsQuarterTwo
      } else if (this.quarter === 3) {
        totalStudentsGoal = this.user.goalStudentsQuarterThree
      } else {
        totalStudentsGoal = this.user.goalStudentsQuarterFour
      }
      return Number(totalStudentsGoal)
    }
  },
  methods: {
    openEditModal () {
      this.$refs.modal.toggleModal()
    },
    update () {
      this.$store.dispatch('GET_GROUPS')
      this.$refs.modal.hideModal()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('SET_LOADING', false)

    setTimeout(() => {
      this.show = false
    }, 2000)
    if (!this.user.onboardingComplete) {
      this.openEditModal()
    }
  },
  created () {
    this.$store.dispatch('SET_LOADING', true)
    this.$store.dispatch('GET_JOURNAL', this.$moment().format('YYYY'))
    this.$store.dispatch('GET_CITIES')
    this.$store.dispatch('GET_REGIONS')
    this.$store.dispatch('GET_COUNTRIES')
    this.$store.dispatch('GET_GROUPS')
    this.$store.dispatch('GET_COACH_STUDENTS', this.user.students)
  }
}
</script>