<template>
  <div class="login">
    <div class="container">
      <br class="d-none d-md-block"><br class="d-none d-md-block">
      <div class="text-center" v-if="isLoading">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-lg-5 pr-lg-0">
            <a class="d-none d-md-block" href="https://kyokushinprofi.com/events/international-cup-of-fellowship-2021/"><img src="https://kyokushinprofi.com/wp-content/uploads/2021/01/fellowship-scaled.jpg" alt="" class="img-fluid"></a>
          </div>
          <div class="col-lg-7 pl-lg-0">
            <div class="card shadow-xs no-border radius10">
              <div class="card-body">
                <p class="text-center"><a href="https://kyokushinprofi.com" target="_blank"><img src="/static/images/logo.svg" alt="" width="150"></a></p>

                <div class="text-center" v-if="registered">
                  <h1 class="h4 mb-4">Ваша заявка принята!</h1>
                  <p class="lead">Благодарим вас за подачу заявки на участие в V Кубке Содружества Федераций Киокушинкай Каратэ.</p>
                  <p class="lead">Подробнее о мероприятии вы можете узнать <a href="https://kyokushinprofi.com/events/international-cup-of-fellowship-2021/" target="_blank">здесь</a>.</p>
                  <p class="lead">По всем вопросами пишите на <a href="mailto:info@kyokushinprofi.com">info@kyokushinprofi.com</a>.</p>
                </div>
                <div v-else>
                  <h1 class="h5 text-center mb-4">Зарегистрироваться на участие в V Кубке Содружества Федераций Киокушинкай Каратэ</h1>

                  <p class="text-center">Регистрация участников закрыта</p>
                  <p class="text-center">Мероприятние вы сможете посмотреть на <a href="https://kyokushinprofi.com/events/international-cup-of-fellowship-2021/">нашем сайте</a>.</p>

                  <!-- <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form @submit.stop.prevent="handleSubmit(register)" class="primary px-lg-3" disabled>

                      <div class="d-md-flex">
                        <validation-provider
                          name="Имя"
                          :rules="{ required: true, min: 2, alpha: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="firstName"
                              type="text"
                              size="lg"
                              v-model="form.firstName"
                              placeholder="Имя"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Фамилия"
                          :rules="{ required: true, min: 2, alpha: true }"
                          v-slot="validationContext"
                          class="ml-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="lastName"
                              type="text"
                              size="lg"
                              v-model="form.lastName"
                              placeholder="Фамилия"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>

                      <label for="">Дата рождения</label>
                      <div class="d-md-flex">
                        <validation-provider
                          name="День"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group label="" class="mr-3 w-100">
                            <b-form-select v-model="form.day" class="" :state="getValidationState(validationContext)">
                              <option :value="null" disabled>День</option>
                              <option v-for="option in dayOptions" :value="option" :key="option">{{ option }}</option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          name="Месяц"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group label="" class="mr-3 w-100">
                            <b-form-select v-model="form.month" class="" :state="getValidationState(validationContext)">
                              <option :value="null" disabled>Месяц</option>
                              <option v-for="option in monthOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          name="Год"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group label="" class="mr-3 w-100">
                            <b-form-select v-model="form.year" class="" :state="getValidationState(validationContext)">
                              <option :value="null" disabled>Год</option>
                              <option v-for="option in yearOptions" :value="option" :key="option">{{ option }}</option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>

                      <div class="d-flex">
                        <validation-provider
                          name="Кю/Дан"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="w-100"
                        >
                          <b-form-group label="Кю/Дан" label-for="belt">
                            <b-form-select v-model="form.danFighter" class="" :state="getValidationState(validationContext)">
                              <option :value="null" disabled>Выберите кю/дан</option>
                              <option v-for="dan in danOptions" :value="dan.text" :key="dan.value">{{ dan.text }}</option>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Пол"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="ml-3 w-100"
                        >
                          <b-form-group label="Пол" label-for="gender" >
                            <b-form-radio-group
                              id="gender"
                              v-model="form.gender"
                              :options="genderOptions"
                              buttons
                              button-variant="light"
                              name="radios-btn-default"
                              class="d-flex"
                              :state="getValidationState(validationContext)"
                            ></b-form-radio-group>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>                     
                      </div>

                      <div class="d-flex">
                        <validation-provider
                          name="Вес"
                          :rules="{ required: true, numeric: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="weight"
                              type="text"
                              size="lg"
                              v-model="form.weight"
                              placeholder="Вес (кг)"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Телефон"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="ml-2 w-100"
                        >
                          <b-form-group label-for="phone">
                            <b-form-input id="phone"
                              type="text"
                              v-model="form.phone"
                              placeholder="Телефон"
                              size="lg"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>

                      <div class="d-md-flex">
                        <validation-provider
                          name="Город/субъект"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="city"
                              type="text"
                              size="lg"
                              placeholder="Город/субъект"
                              v-model="form.city"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Тренер"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                          class="ml-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="coachName"
                              type="text"
                              size="lg"
                              placeholder="Ф.И.О. тренера"
                              v-model="form.coachName"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>

                      <div class="d-md-flex">
                        <validation-provider
                          name="E-mail"
                          :rules="{ email: true, required: true }"
                          v-slot="validationContext"
                          class="mr-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="email"
                              type="email"
                              size="lg"
                              v-model="form.email"
                              placeholder="E-mail"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Пароль"
                          :rules="{required: true, min: 6 }"
                          v-slot="validationContext"
                          class="ml-2 w-100"
                        >
                          <b-form-group>
                            <b-form-input id="password"
                              type="password"
                              size="lg"
                              v-model="form.password"
                              placeholder="Пароль"
                              :state="getValidationState(validationContext)">
                            </b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>
                      
                      <p class="small">Авторизуясь, вы соглашаетесь с <router-link :to="{ name: 'terms' }" target="_blank">правилами пользования сайтом</router-link> и даете <router-link :to="{ name: 'agreement' }" target="_blank">согласие на обработку персональных данных</router-link>.</p>
                      <b-button type="submit" variant="primary" size="lg" block>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                          Зарегистрироваться
                      </b-button>
                    </b-form>
                  </validation-observer> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { generateArrayOfYears, generateArrayOfNumbers, generateArrayOfMonths } from '@/services/utils'
  import { mapState } from 'vuex'

  export default {
    name: 'register',
    data () {
      return {
        isLoading: null,
        hasError: false,
        errorsInfo: {
          firstName: null,
          lastName: '',
          email: null,
          password: null
        },
        registered: false,
        form: {
          firstName: null,
          lastName: null,
          email: null,
          password: null,
          birthday: null,
          danFighter: null,
          phone: null,
          city: null,
          coachName: null,
          gender: 'male',
          weight: null,
          onboardingComplete: false,
          day: null,
          month: null,
          year: null
        },
        yearOptions: generateArrayOfYears(),
        dayOptions: generateArrayOfNumbers(32),
        monthOptions: generateArrayOfMonths()
      }
    },

    beforeCreate () {
      if (this.$store.state.isLoggedIn) {
        this.$router.push({ name: 'home' })
      }
    },

    computed: {
      ...mapState({
        genderOptions: state => state.auth.genderOptions,
        danOptions: state => state.auth.danOptionsAll
      })
    },

    methods: {
      getValidationState ({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      register () {
        const { form } = this
        this.hasError = false
        this.$store.dispatch('REGISTER_FIGHTER', form).then((response) => {
          // this.$router.push({ name: 'profile' })
          this.registered = true
        }, error => {
          this.errorsInfo = error.response.data
          this.hasError = true
        })
      }
    },
    mounted () {
      this.isLoading = true
      this.$store.dispatch('SET_LOADING', true)
      setTimeout(() => {
        this.isLoading = false
        this.$store.dispatch('SET_LOADING', false)
      }, 100)
      let el = document.body
      el.classList.add('bg-light')
    },
    destroyed () {
      let el = document.body
      el.classList.remove('bg-light')
    }
  }
</script>
