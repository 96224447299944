<template>
  <main role="main" class="inner cover text-center">
  </main>
</template>

<script>
export default {
  name: 'landing',
  data () {
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$store.state.auth.isLoggedIn) {
      this.$router.push({ name: 'profile' })
    }
  }
}
</script>

