<template>
  <footer class="mt-auto pt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <ul class="list-style-inline pl-0">
            <li class="list-inline-item"><a class="text-grey" href="https://kyokushinprofi.com/o-nas/" target="_blank">О нас</a></li>
            <li class="list-inline-item"><a class="text-grey" href="https://kyokushinprofi.com/category/news/" target="_blank">Новости</a></li>
            <li class="list-inline-item"><a class="text-grey" href="https://kyokushinprofi.com/zaly/" target="_blank">Залы</a></li>
            <li class="list-inline-item"><a class="text-grey" href="https://kyokushinprofi.com/events/" target="_blank">Мероприятия</a></li>
            <li class="list-inline-item"><a class="text-grey" href="https://kyokushinprofi.com/camp/" target="_blank">Лагерь</a></li>
            <li class="list-inline-item"><a class="text-grey" href="https://kyokushinprofi.com/biblioteka-kyokushinkai/" target="_blank">Все о киокушинкай</a></li>
            <li class="list-inline-item"><router-link :to="{ name: 'terms' }" class="text-grey">Соглашение</router-link></li>
          </ul>
          <p class="small normal text-muted"><a class="text-grey" href="https://kyokushinprofi.com" target="_blank">Международный союз «Киокушин Профи»</a> &copy; 2021</p>
        </div>
        <div class="col-md-4 text-right">
          <p>
            <a class="text-muted pr-3" href="https://instagram.com/kyokushin.profi" rel="nofollow"><img src="/static/images/icons/instagram.svg" alt=""></a>
            <a class="text-muted pr-3" href="https://www.facebook.com/Kyokushinprof1/" rel="nofollow"><img src="/static/images/icons/facebook.svg" alt=""></a>
            <a class="text-muted pr-3" href="https://vk.com/kyokushinprofi_com" rel="nofollow"><img src="/static/images/icons/vk.svg" alt=""></a>
            <a class="text-muted" href="https://www.youtube.com/kyokushinprofi" rel="nofollow"><img src="/static/images/icons/youtube.svg" alt=""></a>
          </p>
          <p class="small normal text-muted">Личный кабинет</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  },
  computed: {
  },
  created () {
  }
}
</script>