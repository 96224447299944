<template>
  <landing></landing>
</template>

<script>
import Landing from './Landing.vue'
export default {
  name: 'baseTemplate',
  components: {
    Landing
  },
  beforeCreate () {
    if (this.$store.state.isLoggedIn) {
      this.$router.push({ name: 'profile' })
    }
  }
}
</script>
