<template>
  <div class="d-flex align-items-center">
    <h3 class="h1 lh1 text-danger mr-1"><strong v-if="percentage">{{ percentage }}</strong></h3>
    <div class="w-100">
      <p class="mb-1 lh1"><strong class="text-danger">%</strong> • {{ current }} из {{ total }}</p>
      <div class="progress" style="height: 10px;">
        <div class="progress-bar bg-danger" role="progressbar" :style="'width:' + percentage + '%'" :aria-valuenow="percentage" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      total: Number,
      current: Number
    },
    data () {
      return {
        isActive: false
      }
    },
    computed: {
      percentage () {
        return Number((this.current * 100 / this.total).toFixed(1))
      }
    },
    methods: {
      toggleFocus () {
        this.isActive = !this.isActive
      }
    }
  }
</script>