<template>
  <div class="container">
    <h1 class="xl">Согласие на обработку персональных данных</h1>

    <p>Пользователь, регистрируясь на интернет-сайте Международного союза «Киокушин Профи» (<a href="https://kyokushinprofi.com" target="_blank">https://kyokushinprofi.com</a>, <a href="https://lk.kyokushinprofi.com" target="_blank">https://lk.kyokushinprofi.com</a>), принимает настоящее Согласие на обработку персональных данных (далее – Согласие).</p>

    <p>Действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, Пользователь дает свое согласие Международному союзу «Киокушин Профи», на обработку своих персональных данных со следующими условиями:</p>
    <p><strong>1.</strong> Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.</p>
    <p><strong>2.</strong> Согласие дается на обработку следующих моих персональных данных, не являющихся специальными или биометрическими:</p>
    <ul>
    <li>изображение профиля (аватар);</li>
    <li>инициалы — фамилия, имя, отчество;</li>
    <li>адреса электронной̆ почты;</li>
    <li>номера контактных телефонов;</li>
    <li>общедоступная информация профиля пользователя в социальных сетях (vk.com, facebook.com, twitter.com, plus.google.com), включая имя, фамилию, фото профиля, возрастной диапазон, пол, язык, страну и другую общедоступную информацию;</li>
    <li>статистические данные — сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана; источник, откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес (с помощью систем Google Analytics, Яндекс.Метрика и др.).</li>
    </ul>
    <p><strong>3.</strong> Персональные данные не являются общедоступными.</p>
    <p><strong>4.</strong> Цель обработки персональных данных:</p>
    <ul>
    <li>статья 24 Конституции Российской Федерации; предоставление прав использования сайта и возмездное оказание услуг по предоставлению дополнительного функционала на условиях Правил пользования сайтом Международного союза «Киокушин Профи»<sup>1</sup>;</li>
    <li>обработка входящих запросов физических лиц с целью оказания консультирования;</li>
    <li>анализ действий пользователей с целью улучшения качества предоставляемых услуг интернет-сайта и разработки новых сервисов, в том числе проверки, исследования и анализа таких данных, позволяющих поддерживать и улучшать, а также разрабатывать новую функциональность интернет-сайта;</li>
    <li>доставка пользователям персонализированных (таргетированных) информационных и рекламных сообщений (в т.ч. рассылок) с учетом статистических данных;</li>
    </ul>
    <p><strong>5.</strong> Основанием для обработки персональных данных является:</p>
    <ul>
    <li>статья 24 Конституции Российской Федерации;</li>
    <li>пункты 1 и 5 части 1 статьи 6 Федерального закона №152-ФЗ «О персональных данных»;</li>
    <li>устав Международный союз «Киокушин Профи»;</li>
    <li>настоящее Согласие.</li>
    </ul>
    <p><strong>6. </strong>В ходе обработки с персональными данными будут совершены следующие действия:</p>
    <ul>
    <li> сбор; запись; систематизация; накопление; хранение; уточнение (обновление, изменение); извлечение; использование; передача (распространение, предоставление, доступ); блокирование; удаление; уничтожение.</li>
    </ul>
    <p><strong>7.</strong> Персональные данные обрабатываются до момента удаления аккаунта физического лица, либо в момент получения запроса субъекта персональных данных на прекращение обработки.</p>
    <p>Хранение персональных данных, зафиксированных на бумажных носителях осуществляется согласно Федеральному закону №125-ФЗ «Об архивном деле в Российской Федерации» и иным нормативно правовым актам в области архивного дела и архивного хранения.</p>
    <p><strong>8.</strong> Согласие может быть отозвано субъектом персональных данных или его представителем путем направления письменного заявления Международный союз «Киокушин Профи» или его представителю по адресу, указанному в начале данного Согласия.</p>
    <p><strong>9. </strong>В случае отзыва субъектом персональных данных или его представителем согласия на обработку персональных данных Международный союз «Киокушин Профи» вправе продолжить обработку персональных данных без согласия субъекта персональных данных при наличии оснований, указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от 27.07.2006 г.</p>
    <p><strong>10.</strong> Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в пунктах 7 и 8 настоящего Согласия.</p>
    <hr>
    <p><sup>1 </sup>Последняя действующая редакция всегда размещена на сайте <a href="https://lk.kyokushinprofi.com/terms" target="_blank">https://lk.kyokushinprofi.com/terms</a></p>
  </div>
</template>

<script>
export default {
  name: 'Agreement',
  data () {
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>