<template>
  <div>
    <div class="text-center" v-if="isLoading">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <studentCard :user="user"></studentCard>
      <hr class="space space-sm">

      <training-calendar></training-calendar>
      <hr class="space space-sm">

      <training-details></training-details>
      <!-- <student-history></student-history> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StudentCard from '../Student/Includes/StudentCard'
import TrainingCalendar from '../Student/Includes/TrainingCalendar'
import TrainingDetails from '../Student/Includes/TrainingDetails'
import StudentHistory from '../Student/Includes/StudentHistory'

export default {
  components: {
    studentCard: StudentCard,
    trainingCalendar: TrainingCalendar,
    trainingDetails: TrainingDetails,
    studentHistory: StudentHistory
  },
  data () {
    return {
      show: true,
      year: this.$moment().year(),
      quarter: this.$moment().quarter()
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      cities: state => state.locations.cities,
      regions: state => state.locations.regions,
      countries: state => state.locations.countries,
      isLoading: state => state.auth.loading
    })
  },
  methods: {
    openEditModal () {
      this.editGroup = null
      setTimeout(() => {
        this.$refs.modal.toggleModal()
      }, 100)
    },
    update () {
      this.$store.dispatch('GET_GROUPS')
      this.$store.dispatch('GET_USER')
      this.$refs.modal.hideModal()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('SET_LOADING', false)

    setTimeout(() => {
      this.show = false
    }, 2000)
  },
  created () {
    this.$store.dispatch('SET_LOADING', true)
    this.$store.dispatch('GET_STUDENT_GROUPS', { coachEmail: this.user.coach, groupSlug: this.user.group })
  }
}
</script>

<style>

</style>
