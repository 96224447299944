<template>
  <div>
    <div class="text-center" v-if="isLoading">
      <b-spinner variant="primary" label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <coachCard :user="user"></coachCard>

      <hr class="space space-sm">

      <training-calendar></training-calendar>      

      <hr class="space space-sm">

      <journal></journal>
      <hr class="space space-sm">
      <!-- 
      <h3 class="mb-4">Города</h3>
      <div class="table-responsive">
        <table class="table" v-if="cities">
          <thead>
            <tr>
              <th>ID</th>
              <th>Страна</th>
              <th>Регион</th>
              <th>Название</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="city in cities" :key="city.id">
              <td>{{ city.id }}</td>
              <td>{{ city.country }}</td>
              <td>{{ city.region }}</td>
              <td>{{ city.title }}</td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>
    <profile-edit :item="user" ref="modal" @update="update"></profile-edit>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TrainingCalendar from '../Coach/Includes/TrainingCalendar'
import Journal from '../Coach/Includes/Journal'
import ProfileEdit from '../Coach/Includes/ProfileEditModal'
import CoachCard from '../Coach/Includes/CoachCard'

export default {
  components: {
    trainingCalendar: TrainingCalendar,
    journal: Journal,
    profileEdit: ProfileEdit,
    coachCard: CoachCard
  },
  data () {
    return {
      show: true,
      year: this.$moment().year(),
      quarter: this.$moment().quarter()
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      cities: state => state.locations.cities,
      regions: state => state.locations.regions,
      countries: state => state.locations.countries,
      isLoading: state => state.auth.loading,
      students: state => state.coaches.students,
      journal: state => state.coaches.journal
    }),
    activeStudents () {
      if (this.students) {
        return this.students.filter((student) => {
          return !student.isActive
        })
      }
    },
    activeStudentsFromJournal () {
      let students = []
      if (this.journal) {
        for (let [month] of Object.entries(this.journal)) {
          for (let [day] of Object.entries(this.journal[month])) {
            if (this.journal[month][day].students && this.journal[month][day].students.length > 0) {
              this.journal[month][day].students.forEach((student) => {
                students.push(student)
              })
            }
          }
        }
        return [ ...new Set(students) ]
      }
    },
    activeStudentsCurrentQuarter () {
      let students = []
      let months = []
      if (this.journal) {
        if (this.quarter === 1) {
          months = ['12', '01', '02']
        } else if (this.quarter === 2) {
          months = ['03', '04', '05']
        } else if (this.quarter === 3) {
          months = ['06', '07', '08']
        } else {
          months = ['09', '10', '11']
        }
        for (let [month] of Object.entries(this.journal)) {
          if (months.includes(month)) {
            for (let [day] of Object.entries(this.journal[month])) {
              if (this.journal[month][day].students && this.journal[month][day].students.length > 0) {
                this.journal[month][day].students.forEach((student) => {
                  students.push(student)
                })
              }
            }
          }
        }
        return [ ...new Set(students) ]
      }
    },
    quarterStudentsGoal () {
      let totalStudentsGoal = null
      if (this.quarter === 1) {
        totalStudentsGoal = this.user.goalStudentsQuarterOne
      } else if (this.quarter === 2) {
        totalStudentsGoal = this.user.goalStudentsQuarterTwo
      } else if (this.quarter === 3) {
        totalStudentsGoal = this.user.goalStudentsQuarterThree
      } else {
        totalStudentsGoal = this.user.goalStudentsQuarterFour
      }
      return Number(totalStudentsGoal)
    }
  },
  methods: {
    openEditModal () {
      this.editGroup = null
      setTimeout(() => {
        this.$refs.modal.toggleModal()
      }, 100)
    },
    update () {
      this.$store.dispatch('GET_GROUPS')
      this.$refs.modal.hideModal()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('SET_LOADING', false)

    setTimeout(() => {
      this.show = false
    }, 2000)
  },
  created () {
    this.$store.dispatch('SET_LOADING', true)
    this.$store.dispatch('GET_JOURNAL', this.$moment().format('YYYY'))
    this.$store.dispatch('GET_CITIES')
    this.$store.dispatch('GET_REGIONS')
    this.$store.dispatch('GET_COUNTRIES')
    this.$store.dispatch('GET_GROUPS')
    this.$store.dispatch('GET_COACH_STUDENTS', this.user.students)
  }
}
</script>