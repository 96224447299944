import Vue from 'vue'
import Router from 'vue-router'

import Base from '@/components/Base'
import Login from '@/components/Auth/Login'
import AdminLogin from '@/components/Auth/AdminLogin'
import Register from '@/components/Auth/RegisterCup'
import Reset from '@/components/Auth/Reset'
import PasswordEdit from '@/components/Profile/PasswordEdit'

// Coaches
import ProfileBase from '@/components/Profile/ProfileBase'
import ProfileEdit from '@/components/Profile/ProfileEdit'
import Students from '@/components/Coach/Students'

// Managers
import Coaches from '@/components/Manager/Coaches'

import Help from '@/components/Help/Help'
import Knowledge from '@/components/Knowledge/Knowledge'
import Marketing from '@/components/Marketing/Marketing'

import Dashboard from '@/components/Admin/Dashboard'

import Cup from '@/components/Cup/Cup'

// Static pages
import Terms from '@/components/Static/Terms'
import Agreement from '@/components/Static/Agreement'

import BaseDashboard from '@/components/BaseDashboard'

import { store } from '@/store'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'base',
      component: Base,
      meta: { requiresAuth: true }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Dashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: Register
    },
    {
      path: '/admin-login',
      name: 'admin-login',
      component: AdminLogin
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/reset',
      name: 'reset',
      component: Reset
    },
    {
      path: '/profile',
      component: BaseDashboard,
      children: [
        {
          path: '',
          name: 'profile',
          component: ProfileBase,
          meta: { requiresAuth: true }
        },
        {
          path: 'edit',
          name: 'profile-edit',
          component: ProfileEdit,
          meta: { requiresAuth: true }
        },
        {
          path: 'password',
          name: 'password-edit',
          component: PasswordEdit,
          meta: { requiresAuth: true }
        }
      ]
    },
    {
      path: '/help',
      name: 'help',
      component: Help
    },
    {
      path: '/knowledge',
      name: 'knowledge',
      component: Knowledge
    },
    {
      path: '/marketing',
      name: 'marketing',
      component: Marketing
    },
    {
      path: '/students',
      name: 'students',
      component: Students,
      meta: { requiresAuth: true }
    },
    {
      path: '/cup',
      name: 'cup',
      component: Cup,
      meta: { requiresAuth: true }
    },
    {
      path: '/coaches',
      name: 'coaches',
      component: Coaches,
      meta: { requiresAuth: true }
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: Agreement
    },
    {
      path: '*',
      component: Base
    }
  ]
})

router.beforeEach((to, from, next) => {
  let currentUser = store.state.auth.user
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) next('login')
  else if (requiresAuth && currentUser) next()
  else next()
})

export default router
