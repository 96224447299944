import firebase from 'firebase/app'
const db = firebase.firestore()
var moment = require('moment')

const state = {
  isLoggedIn: false,
  user: null,
  loading: false,
  error: null,
  coachStatuses: [
    { value: true, text: 'Да' },
    { value: false, text: 'Нет' }
  ],
  studentStatuses: [
    { value: true, text: 'Да' },
    { value: false, text: 'Нет' }
  ],
  genderOptions: [
    { value: 'male', text: 'Мужской' },
    { value: 'female', text: 'Женский' }
  ],
  statusOptions: [
    { text: 'Активный', value: true },
    { text: 'Неактивный', value: false }
  ],
  colourOptions: [
    { value: '#60BB58', text: '1' },
    { value: '#5364F4', text: '2' },
    { value: '#EBB700', text: '3' },
    { value: '#DE58F4', text: '4' },
    { value: '#36A77E', text: '5' },
    { value: '#653CDB', text: '6' },
    { value: '#FC832C', text: '7' },
    { value: '#DA0BB9', text: '8' },
    { value: '#3FEDF9', text: '9' },
    { value: '#000000', text: '10' }
  ],
  studentPaymentType: [
    { text: 'Платная', value: true },
    { text: 'Бесплатная', value: false }
  ],
  beltOptions: [
    { value: 'white', text: 'Белый (0 кю)' },
    { value: 'orange', text: 'Оранжевый (10 кю)' },
    { value: 'orangeBlue', text: 'Оранжевый с синей полоской (9 кю)' },
    { value: 'blue', text: 'Синий (8 кю)' },
    { value: 'blueYellow', text: 'Синий с желтой полоской (7 кю)' },
    { value: 'yellow', text: 'Желтый (6 кю)' },
    { value: 'yellowGreen', text: 'Желтый с зеленой полоской (5 кю)' },
    { value: 'green', text: 'Зеленый (4 кю)' },
    { value: 'greenBrown', text: 'Зеленый с коричневой полоской (3 кю)' },
    { value: 'brown', text: 'Коричневый (2 кю)' },
    { value: 'brownGolden', text: 'Коричневый с золотой полоской (1 кю)' },
    { value: 'black', text: 'Черный' }
  ],
  danOptions: [
    { value: '1', text: '1 семпай' },
    { value: '2', text: '2 семпай' },
    { value: '3', text: '3 сенсей' },
    { value: '4', text: '4 сенсей' },
    { value: '5', text: '5 сихан' },
    { value: '6', text: '6 сихан' },
    { value: '7', text: '7 сихан' },
    { value: '8', text: '8 ханси' },
    { value: '9', text: '9 ханси' },
    { value: '10', text: '10 мэйдзин' }
  ],
  danOptionsAll: [
    { value: '10kyu', text: '10 кю' },
    { value: '9kyu', text: '9 кю' },
    { value: '8kyu', text: '8 кю' },
    { value: '7kyu', text: '7 кю' },
    { value: '6kyu', text: '6 кю' },
    { value: '5kyu', text: '5 кю' },
    { value: '4kyu', text: '4 кю' },
    { value: '3kyu', text: '3 кю' },
    { value: '2kyu', text: '2 кю' },
    { value: '1kyu', text: '1 кю' },
    { value: '1dan', text: '1 дан' },
    { value: '2dan', text: '2 дан' },
    { value: '3dan', text: '3 дан' },
    { value: '4dan', text: '4 дан' },
    { value: '5dan', text: '5 дан' },
    { value: '6dan', text: '6 дан' },
    { value: '7dan', text: '7 дан' },
    { value: '8dan', text: '8 дан' },
    { value: '9dan', text: '9 дан' },
    { value: '10dan', text: '10 дан' }
  ]
}

const actions = {
  LOGIN_USER ({ commit }, { email, password }) {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    firebase.auth().signInWithEmailAndPassword(email, password).then((userRes) => {
      // var user = firebase.auth().currentUser
      commit('SET_LOADING', false)
      // commit('SET_LOADED_USER', user)

      db.collection('users').doc(userRes.user.email).get().then((doc) => {
        if (doc.exists) {
          db.collection('users').doc(userRes.user.email).update({
            lastLogin: moment().format()
          })
          commit('SET_LOADED_USER', doc.data())
        } else {
          db.collection('students').doc(userRes.user.uid).get().then((doc) => {
            if (doc.exists) {
              db.collection('students').doc(userRes.user.uid).update({
                lastLogin: moment().format()
              })
              commit('SET_LOADED_USER', doc.data())
            }
          })
        }
      })
    }).catch(error => {
      commit('SET_LOADING', false)
      commit('SET_ERROR', error)
    })
  },
  REGISTER ({ commit }, form) {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    firebase.auth().createUserWithEmailAndPassword(form.email, form.password).then((response) => {
      // create user obj
      let collectionType = 'users'
      let collectionId = response.user.email
      if (form.isStudent) {
        collectionType = 'students'
        collectionId = response.user.uid
      }
      db.collection(collectionType).doc(collectionId).set({
        id: response.user.uid,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        lastLogin: moment().format(),
        created: moment().format(),
        isCoach: form.isCoach,
        isManager: form.isManager,
        isStudent: form.isStudent,
        onboardingComplete: form.onboardingComplete
      }).then(() => {
        commit('SET_LOADING', false)
        // commit('SET_LOADED_USER', response.user)
        db.collection(collectionType).doc(collectionId).get().then((doc) => {
          if (doc) {
            commit('SET_LOADED_USER', doc.data())
          }
        })
      }).catch(error => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      })
    }).catch(error => {
      commit('SET_LOADING', false)
      commit('SET_ERROR', error)
    })
  },
  REGISTER_FIGHTER ({ commit }, form) {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    firebase.auth().createUserWithEmailAndPassword(form.email, form.password).then((response) => {
      // create user obj
      let collectionType = 'fighters2021'
      let collectionId = response.user.email
      db.collection(collectionType).doc(collectionId).set({
        id: response.user.uid,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        lastLogin: moment().format(),
        created: moment().format(),
        isFighter: true,
        isStudent: true,
        weight: form.weight,
        phone: form.phone,
        danFighter: form.danFighter,
        birthday: form.birthday,
        gender: form.gender,
        city: form.city,
        coachName: form.coachName,
        isCoach: false,
        isManager: false,
        onboardingComplete: form.onboardingComplete,
        day: form.day,
        month: form.month,
        year: form.year
      }).then(() => {
        commit('SET_LOADING', false)
        // commit('SET_LOADED_USER', response.user)
        // db.collection(collectionType).doc(collectionId).get().then((doc) => {
        //   if (doc) {
        //     commit('SET_LOADED_USER', doc.data())
        //   }
        // })
      }).catch(error => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      })

      db.collection('students').doc(response.user.uid).set({
        id: response.user.uid,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        lastLogin: moment().format(),
        created: moment().format(),
        isCoach: false,
        isManager: false,
        isStudent: true,
        onboardingComplete: form.onboardingComplete
      }).then(() => {
        commit('SET_LOADING', false)
        // commit('SET_LOADED_USER', response.user)
        // db.collection(collectionType).doc(collectionId).get().then((doc) => {
        //   if (doc) {
        //     commit('SET_LOADED_USER', doc.data())
        //   }
        // })
      }).catch(error => {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      })
    }).catch(error => {
      commit('SET_LOADING', false)
      commit('SET_ERROR', error)
    })
  },
  RESET_PASSWORD ({ commit }, { email }) {
    commit('SET_LOADING', true)
    commit('CLEAR_ERROR')
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      commit('SET_LOADING', false)
    }).catch(error => {
      commit('SET_LOADING', false)
      commit('SET_ERROR', error)
    })
  },
  AUTO_SIGN_IN ({ commit }, payload) {
    db.collection('users').doc(payload.email).get().then((doc) => {
      if (doc.exists) {
        commit('SET_LOADED_USER', doc.data())
      } else {
        db.collection('students').doc(payload.uid).get().then((doc) => {
          if (doc.exists) {
            commit('SET_LOADED_USER', doc.data())
          }
        })
      }
    })
    // commit('SET_LOADED_USER')
    // commit('SET_LOADED_USER', payload)
  },
  LOGOUT ({ commit }) {
    firebase.auth().signOut()
    window.location.href = window.location.href
    localStorage.removeItem('token')
  },
  SET_LOADING ({ commit }, payload) {
    commit('SET_LOADING', payload)
  },
  SET_ERROR ({ commit }, payload) {
    commit('SET_ERROR')
  },
  CLEAR_ERROR ({ commit }) {
    commit('CLEAR_ERROR')
  },
  GET_USER ({ commit }) {
    var userEmail = firebase.auth().currentUser.email
    var id = firebase.auth().currentUser.uid
    return new Promise((resolve, reject) => {
      db.collection('users').doc(userEmail).get().then((doc) => {
        if (doc.exists) {
          commit('SET_LOADED_USER', doc.data())
          resolve(doc)
        } else {
          db.collection('students').doc(id).get().then((doc) => {
            if (doc.exists) {
              commit('SET_LOADED_USER', doc.data())
              resolve(doc)
            }
          })
        }
      })
    })
  },
  UPDATE_PROFILE ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let collectionType = 'users'
      let collectionId = payload.form.email
      if (payload.form.isStudent) {
        collectionType = 'students'
        collectionId = payload.id
      }
      db.collection(collectionType).doc(collectionId).update(payload.form).then(snapshot => {
        db.collection(collectionType).doc(collectionId).get().then((doc) => {
          if (doc) {
            commit('SET_LOADED_USER', doc.data())
            resolve(snapshot)
          }
        })
      })
    })
  }
}

const getters = {
  loadedUser: (state) => (id) => {
    return state.users.find(user => user.id === id)
  },
  getUser (state) {
    return state.user
  },
  isLoading (state) {
    return state.loading
  },
  getError (state) {
    return state.error
  }
}

const mutations = {
  SET_LOADED_USER (state, payload) {
    state.user = payload
  },
  SET_LOADING (state, payload) {
    state.loading = payload
  },
  SET_ERROR (state, payload) {
    state.error = payload
  },
  CLEAR_ERROR (state) {
    state.error = null
  },
  LOGOUT (state) {
    state.user = null
    state.token = null
    state.isLoggedIn = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
