<template>
  <b-modal 
    id="modal-1"
    ref="modal"
    :title="title"
    @show="toggleModal"
    @hidden="resetModal"
    @ok="handleOk"
    :ok-title="btnTitle"
    hide-footer
    cancel-title="Отмена"
    cancel-variant="light"
    footer-class="justify-content-between"
  >
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form ref="form" @submit.stop.prevent="handleSubmit(submit)">
        <div class="d-flex">
          <b-form-group label="E-mail" label-for="email" class="mr-2 w-100">
            <b-form-input id="email"
              type="text"
              disabled
              v-model="form.email">
            </b-form-input>
          </b-form-group>
          <validation-provider
            name="Сотовый телефон"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="ml-2 mr-2 w-100"
          >
            <b-form-group label="Сотовый телефон *" label-for="phone">
              <b-form-input id="phone"
                type="text"
                v-model="form.phone"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="d-flex">
          <validation-provider
            name="Фамилия"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Фамилия *" label-for="lastName" class="mr-3 w-100">
              <b-form-input id="lastName"
                type="text"
                v-model="form.lastName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Имя"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Имя *" label-for="firstName" class="mr-3 w-100">
              <b-form-input id="firstName"
                type="text"
                v-model="form.firstName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Отчество"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-0"
          >
            <b-form-group label="Отчество *" label-for="middleName" class="w-100">
              <b-form-input id="middleName"
                type="text"
                v-model="form.middleName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="d-flex">
          <validation-provider
            name="Дата рождения"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="mr-2 w-100"
          >
            <b-form-group label="Дата рождения *" label-for="middleName" class="w-100">
              <b-form-datepicker
                placeholder="Дата рождения"
                v-model="form.birthday"
                show-decade-nav
                label-no-date-selected="Дата не выбрана"
                label-help="Выберите дату рождения"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                :state="getValidationState(validationContext)"
                locale="ru">
              </b-form-datepicker>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Пол"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="ml-2"
          >
            <b-form-group label="Пол *" label-for="gender">
              <b-form-radio-group
                id="gender"
                v-model="form.gender"
                :options="genderOptions"
                buttons
                button-variant="light"
                name="radios-btn-default"
                :state="getValidationState(validationContext)"
              ></b-form-radio-group>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="d-flex">
          <validation-provider
            name="Регион"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="mr-2 w-100"
          >
            <b-form-group label="Регион *" label-for="parent" class="mr-3 w-100">
              <b-form-select v-model="form.region" class="" :state="getValidationState(validationContext)">
                <option :value="null" disabled>Выберите регион</option>
                <option v-for="option in regions" :value="option.id" :key="option.id">{{ option.title }}</option>
              </b-form-select>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Город"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="ml-2 w-100"
          >
            <b-form-group label="Город *" label-for="parent" class="mr-3 w-100">
              <b-form-select v-model="form.city" class="" :disabled="!form.region" :state="getValidationState(validationContext)">
                <option :value="null" disabled>Выберите город</option>
                <option v-for="option in filteredCities" :value="option.id" :key="option.id">{{ option.title }}</option>
              </b-form-select>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <b-form-group label="Город" label-for="city" class="w-100">
            <b-form-input id="city"
              type="text"
              v-model="form.city"
              required>
            </b-form-input>
          </b-form-group> -->
        </div>

        <!-- <div class="d-flex">
          <validation-provider
            name="Улица"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="w-100 mr-3"
          >
            <b-form-group label="Улица *" label-for="street">
              <b-form-input id="street"
                type="text"
                v-model="form.street"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Дом" class="mr-3" label-for="house">
            <b-form-input id="house"
              type="text"
              v-model="form.house">
            </b-form-input>
          </b-form-group>
          <b-form-group label="Квартира" label-for="apartment">
            <b-form-input id="apartment"
              type="text"
              v-model="form.apartment">
            </b-form-input>
          </b-form-group>
        </div> -->

        <div class="d-flex align-items-center">
          <b-form-group label="Экстренный контакт" label-for="extraNumber" class="w-100">
            <b-form-input id="extraNumber"
              type="text"
              v-model="form.extraNumber">
            </b-form-input>
          </b-form-group>
          <div class="ml-3">
            <p class="small lh1 text-muted">Здесь необходимо указать номер человека, с которым можно будет связаться в случае экстренной ситуации.</p>
          </div>
        </div>

        <div class="d-flex" v-if="user.isManager">
          <b-form-group label="Пояс" label-for="belt" class="mr-3 w-100">
            <b-form-select v-model="form.belt" class="">
              <option :value="null" disabled>Выберите пояс</option>
              <option v-for="belt in beltOptions" :value="belt.value" :key="belt.value">{{ belt.text }}</option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Дан" label-for="belt" class="w-100">
            <b-form-select v-model="form.dan" class="">
              <option :value="null" disabled>Выберите дан</option>
              <option v-for="dan in danOptions" :value="dan.value" :key="dan.value">{{ dan.text }}</option>
            </b-form-select>
          </b-form-group>
        </div>

        <!-- <b-form-group label="Тренер" label-for="isCoach">
          <b-form-radio-group
            id="isCoach"
            v-model="form.isCoach"
            :options="coachStatuses"
            buttons
            button-variant="light"
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Студент" label-for="isStudent">
          <b-form-radio-group
            id="isStudent"
            v-model="form.isStudent"
            :options="studentStatuses"
            buttons
            button-variant="light"
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group> -->
        <b-button type="submit" variant="primary" size="lg" block>
          Сохранить
        </b-button>
      </b-form>
    </validation-observer>
    <b-button type="submit" variant="light" size="lg" block @click="hideModal" class="mt-2">
      Отмена
    </b-button>
  </b-modal>
</template>

<script>
import { slugify } from '@/services/utils'
import { mapState } from 'vuex'

export default {
  props: {
    item: [Object]
  },
  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        middleName: null,
        gender: 'male',
        country: 'russia',
        region: null,
        // street: null,
        // house: null,
        // apartment: null,
        city: null,
        isCoach: null,
        isStudent: null,
        email: null,
        birthday: null,
        belt: null,
        dan: null,
        extraNumber: null,
        phone: null,
        onboardingComplete: true
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      countries: state => state.locations.countries,
      regions: state => state.locations.regions,
      cities: state => state.locations.cities,
      coachStatuses: state => state.auth.coachStatuses,
      genderOptions: state => state.auth.genderOptions,
      studentStatuses: state => state.auth.studentStatuses,
      groups: state => state.coaches.groups,
      beltOptions: state => state.auth.beltOptions,
      danOptions: state => state.auth.danOptions
    }),
    groupOptions () {
      return Object.keys(this.groups).map(group => {
        return { value: this.groups[group].slug, text: this.groups[group].title }
      }).sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
    },
    slug () {
      return slugify(this.form.city)
    },
    slugRegion () {
      return slugify(this.form.region)
    },
    title () {
      if (this.item) {
        return 'Редактирование профиля'
      } else {
        return 'Создать тренера'
      }
    },
    btnTitle () {
      if (this.item) {
        return 'Сохранить'
      } else {
        return 'Создать'
      }
    },
    filteredCities () {
      return this.cities.filter((city) => {
        return city.region === this.form.region
      })
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    submit () {
      this.form.city = this.slug
      this.form.region = this.slugRegion
      const { form } = this
      this.$store.dispatch('UPDATE_PROFILE', { form, id: this.item.id }).then((response) => {
        this.submitted = true
        this.$emit('update')
        this.$store.dispatch('GET_USER')
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }, error => {
        console.log(error)
      })
    },
    resetModal () {
      this.form.firstName = null
      this.form.lastName = null
      this.form.middleName = null
      this.form.gender = 'male'
      this.form.country = 'russia'
      // this.form.street = null
      // this.form.house = null
      // this.form.apartment = null
      this.form.city = null
      this.form.region = null
      this.form.isCoach = null
      this.form.isStudent = null
      this.form.email = null
      this.form.birthday = null
      this.form.belt = null
      this.form.dan = null
      this.form.extraNumber = null
      this.form.phone = null
      this.form.onboardingComplete = true
    },
    showModal () {
      this.$refs['modal'].show()
    },
    hideModal () {
      this.$refs['modal'].hide()
    },
    toggleModal () {
      this.resetModal()
      if (this.item) {
        this.form.firstName = this.item.firstName
        this.form.lastName = this.item.lastName
        this.form.middleName = this.item.middleName
        this.form.gender = this.item.gender ? this.item.gender : 'male'
        this.form.country = this.item.country ? this.item.country : 'russia'
        // this.form.street = this.item.street ? this.item.street : ''
        // this.form.house = this.item.house ? this.item.house : ''
        // this.form.apartment = this.item.apartment ? this.item.apartment : ''
        this.form.city = this.item.city
        this.form.region = this.item.region
        this.form.isCoach = this.item.isCoach ? this.item.isCoach : false
        this.form.isStudent = this.item.isStudent ? this.item.isStudent : false
        this.form.email = this.item.email
        this.form.birthday = this.item.birthday ? this.item.birthday : null
        this.form.belt = this.item.belt ? this.item.belt : null
        this.form.dan = this.item.dan ? this.item.dan : null
        this.form.extraNumber = this.item.extraNumber ? this.item.extraNumber : null
        this.form.phone = this.item.phone ? this.item.phone : null
        this.form.onboardingComplete = this.item.onboardingComplete ? this.item.onboardingComplete : true
      }
      this.$refs['modal'].show()
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
  }
}
</script>