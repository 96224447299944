import Vue from 'vue'
import VueRouter from 'vue-router'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue2Filters from 'vue2-filters'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import * as rules from 'vee-validate/dist/rules'

import GlobalComponents from './globalComponents'

import router from './router'
import { store } from './store'
import './filters'

// Library imports
import './assets/sass/main.scss'

const moment = require('moment')
require('moment/locale/ru')

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(GlobalComponents)
Vue.use(Vue2Filters)
Vue.use(require('vue-moment'), { moment })

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

localize('ru', ru)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// A secondary Vue instance to communicate between components.
var EventHolder = new Vue()
Vue.prototype.EventHolder = EventHolder

Sentry.init({
  dsn: 'https://81c0a8f0c22a4e719221f0f24d1d2498@sentry.io/5190746',
  integrations: [new Integrations.Vue({Vue, attachProps: true})]
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render (h) {
    return h(App)
  }
})
