<template>
  <div>
    <h1>Персональная информация</h1>

    <div class="row">
      <div class="col-md-4">
        <b-form @submit.prevent="submit">
          <div class="d-flex">
            <b-form-group label="Имя" label-for="firstName" class="mr-3 w-100">
              <b-form-input id="firstName"
                type="text"
                v-model="form.firstName"
                required>
              </b-form-input>
            </b-form-group>
            <b-form-group label="Фамилия" label-for="lastName" class="w-100">
              <b-form-input id="lastName"
                type="text"
                v-model="form.lastName"
                required>
              </b-form-input>
            </b-form-group>
          </div>

          <b-form-group label="Пол" label-for="gender">
            <b-form-radio-group
              id="gender"
              v-model="form.gender"
              :options="genderOptions"
              buttons
              button-variant="light"
              name="radios-btn-default"
              required
            ></b-form-radio-group>
          </b-form-group>

          <div class="d-flex">
            <b-form-group label="Страна" label-for="parent" class="mr-3 w-100">
              <b-form-select v-model="form.country" class="mb-3" required>
                <option :value="null" disabled>Выберите страну</option>
                <option v-for="option in countries" :value="option.id" :key="option.id">{{ option.title }}</option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Город" label-for="city" class="w-100">
              <b-form-input id="city"
                type="text"
                v-model="form.city"
                required>
              </b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex">
            <b-form-group label="Улица" class="w-100 mr-3" label-for="street">
              <b-form-input id="street"
                type="text"
                v-model="form.street">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Дом" class="mr-3" label-for="house">
              <b-form-input id="house"
                type="text"
                v-model="form.house">
              </b-form-input>
            </b-form-group>
            <b-form-group label="Квартира" label-for="apartment">
              <b-form-input id="apartment"
                type="text"
                v-model="form.apartment">
              </b-form-input>
            </b-form-group>
          </div>

          <b-form-group label="Тренер" label-for="isCoach">
            <b-form-radio-group
              id="isCoach"
              v-model="form.isCoach"
              :options="coachStatuses"
              buttons
              button-variant="light"
              name="radios-btn-default"
            ></b-form-radio-group>
          </b-form-group>

          <b-form-group label="Студент" label-for="isStudent">
            <b-form-radio-group
              id="isStudent"
              v-model="form.isStudent"
              :options="studentStatuses"
              buttons
              button-variant="light"
              name="radios-btn-default"
            ></b-form-radio-group>
          </b-form-group>
          <b-button type="submit" variant="primary">Сохранить</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from '@/firebase'
import { mapState } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
      submitted: false,
      hasError: false,
      errorInfo: null,
      form: {
        firstName: null,
        lastName: null,
        gender: null,
        country: null,
        street: null,
        house: null,
        apartment: null,
        cty: null,
        isCoach: null,
        isStudent: null,
        email: null
      },
      genderOptions: [
        { value: 'male', text: 'Мужской' },
        { value: 'female', text: 'Женский' }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      countries: state => state.locations.countries,
      regions: state => state.locations.regions,
      coachStatuses: state => state.auth.coachStatuses,
      studentStatuses: state => state.auth.studentStatuses
    })
  },
  methods: {
    submit () {
      const { form } = this
      this.$store.dispatch('UPDATE_PROFILE', { form, id: this.user.id }).then((response) => {
        this.submitted = true
      }, error => {
        console.log(error)
      })
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.form.firstName = this.$store.getters.getUser.firstName ? this.$store.getters.getUser.firstName : ''
    this.form.lastName = this.$store.getters.getUser.lastName ? this.$store.getters.getUser.lastName : ''
    this.form.gender = this.$store.getters.getUser.gender ? this.$store.getters.getUser.gender : ''
    this.form.country = this.$store.getters.getUser.country ? this.$store.getters.getUser.country : ''
    this.form.street = this.$store.getters.getUser.street ? this.$store.getters.getUser.street : ''
    this.form.house = this.$store.getters.getUser.house ? this.$store.getters.getUser.house : ''
    this.form.apartment = this.$store.getters.getUser.apartment ? this.$store.getters.getUser.apartment : ''
    this.form.city = this.$store.getters.getUser.city ? this.$store.getters.getUser.city : ''
    this.form.isCoach = this.$store.getters.getUser.isCoach ? this.$store.getters.getUser.isCoach : ''
    this.form.isStudent = this.$store.getters.getUser.isStudent ? this.$store.getters.getUser.isStudent : ''
    this.form.email = this.$store.getters.getUser.email ? this.$store.getters.getUser.email : ''
  },
  created () {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('AUTO_SIGN_IN', user)
      }
    })
  }
}
</script>