<template>
<div class="container-fluid">
  <router-view></router-view>
</div>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    methods: {
    }
  }
</script>
