import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import locations from './modules/locations'
import coaches from './modules/coaches'
import managers from './modules/managers'
import students from './modules/students'
import cup from './modules/cup'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'token',
      paths: []
    })
  ],
  modules: {
    auth,
    coaches,
    locations,
    managers,
    students,
    cup
  }
})
