const slugifyIt = require('@sindresorhus/slugify')

export const slugify = (string) => {
  return slugifyIt(string, { separator: '_' })
  // const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  // const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  // const p = new RegExp(a.split('').join('|'), 'g')

  // return string.toString().toLowerCase()
  //   .replace(/\s+/g, '-') // Replace spaces with -
  //   .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
  //   .replace(/&/g, '-and-') // Replace & with 'and'
  //   .replace(/[^\w]+/g, '') // Remove all non-word characters
  //   .replace(/-+/g, '-') // Replace multiple - with single -
  //   .replace(/^-+/, '') // Trim - from start of text
  //   .replace(/-+$/, '') // Trim - from end of text
}

export const objectToArray = (obj) => {
  return Object.keys(obj).map(function (key) {
    return {
      id: obj[key]['slug'],
      text: obj[key]['title']
    }
  })
}

export const objectToArraySafe = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    return objectToArray(obj)
  }
  return []
}

export const generateArrayOfYears = () => {
  var max = new Date().getFullYear()
  var min = max - 100
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}

export const generateArrayOfNumbers = (numbers) => {
  return [...Array(numbers).keys()].slice(1)
}

export const generateArrayOfMonths = () => {
  var months = [
    { value: '1', text: 'января' },
    { value: '2', text: 'февраля' },
    { value: '3', text: 'марта' },
    { value: '4', text: 'апреля' },
    { value: '5', text: 'мая' },
    { value: '6', text: 'июня' },
    { value: '7', text: 'июля' },
    { value: '8', text: 'августа' },
    { value: '9', text: 'сентября' },
    { value: '10', text: 'октабря' },
    { value: '11', text: 'ноября' },
    { value: '12', text: 'декабря' }
  ]
  return months
}
