<template>
  <div class="container-fluid">
    <h1 class="xl">Маркетинг</h1>
  </div>
</template>

<script>
export default {
  name: 'marketing',
  data () {
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
