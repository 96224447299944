<template>
  <div class="form-group is-active">
    <label class="custom-label control-label">{{label}}</label>
    <select class="form-control custom-select" title="" required="">
      <option v-for="option in options" :value="option.id" :key="option.id" :selected="value==option">{{ option }}</option>
    </select>
    <div v-if="help" class="help-block">{{ help }}</div>
  </div>
</template>
<script>
  export default {
    props: {
      label: String,
      name: String,
      disabled: Boolean,
      placeholder: String,
      help: String,
      value: [String, Number, Array],
      options: {
        type: Array,
        default () {
          return []
        }
      }
    },
    data () {
      return {
        isActive: false
      }
    },
    methods: {
      toggleFocus () {
        this.isActive = !this.isActive
      }
    }
  }
</script>