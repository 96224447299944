import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = process.env.FIREBASE

function initFirebase () {
  return new Promise(resolve => {
    firebase.initializeApp(config)
  })
}

initFirebase()

const db = firebase.firestore()

const settings = {}

db.settings(settings)

// db.enablePersistence()
//   .catch(function (err) {
//     if (err.code === 'failed-precondition') {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === 'unimplemented') {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   })

export default firebase
