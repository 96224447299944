<template>
  <div class="login">
    <div class="container">
      <br class="d-none d-md-block"><br class="d-none d-md-block">
      <div class="text-center" v-if="isLoading">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="row mt-5">
          <div class="col-md-6 offset-md-3">
            <div class="card shadow-xs no-border radius10">
              <div class="card-body">
                <p class="text-center mt-3"><router-link :to="{ name: 'base' }"><img src="/static/images/logo.svg" alt=""></router-link></p>
                <h1 class="h3 text-center mb-4">Войти в личный кабинет</h1>
                
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.stop.prevent="handleSubmit(signIn)" class="primary px-md-5">
                    <validation-provider
                      name="E-mail"
                      :rules="{ email: true, required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-input id="email"
                          type="email"
                          size="lg"
                          v-model="email"
                          placeholder="E-mail"
                          :state="getValidationState(validationContext)">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="Пароль"
                      :rules="{required: true, min: 6 }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-input id="password"
                          type="password"
                          size="lg"
                          v-model="password"
                          placeholder="Пароль"
                          :state="getValidationState(validationContext)">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  <div class="row">
                    <div class="col"></div>
                    <div class="col text-right">
                      <p><router-link :to="{ name: 'reset' }">Забыли пароль?</router-link></p>
                    </div>
                  </div>
                  <div class="text-danger my-3" v-if="error">
                    <p class="lead" v-if="error.code = 'auth/wrong-password'">Неверный пароль</p>
                    <p class="lead" v-else>{{ error.message }}</p>
                  </div>
                  <b-button type="submit" variant="primary" size="lg" block>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                      Войти
                  </b-button>
                </b-form>
                </validation-observer>
                <br>
                <p class="text-center">Или <router-link :to="{ name: 'register' }">зарегистрироваться</router-link></p>     
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import firebase from '@/firebase'
  export default {
    name: 'login',
    data () {
      return {
        isLoading: null,
        email: null,
        password: null
      }
    },
    computed: {
      user () {
        return this.$store.getters.getUser
      },
      error () {
        return this.$store.getters.getError
      }
    },
    mounted () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 100)

      if (this.user !== null && this.user !== undefined) {
        this.$router.push({ name: 'profile' })
      }

      let el = document.body
      el.classList.add('bg-light')
    },
    methods: {
      getValidationState ({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null
      },
      signIn () {
        const user = {
          email: this.email,
          password: this.password
        }
        this.$store.dispatch('LOGIN_USER', user)
      }
    },
    created () {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$store.dispatch('AUTO_SIGN_IN', user)
        }
      })
    },
    destroyed () {
      let el = document.body
      el.classList.remove('bg-light')
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push({ name: 'profile' })
        }
      }
    }
  }
</script>