<template>
  <b-modal 
    id="modal-1"
    ref="modal"
    :title="title"
    @show="toggleModal"
    @hidden="resetModal"
    @ok="handleOk"
    :ok-title="btnTitle"
    hide-footer
    cancel-title="Отмена"
    cancel-variant="light"
    footer-class="justify-content-between"
  >
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form ref="form" @submit.stop.prevent="handleSubmit(submit)">
        <div class="d-flex">
          <b-form-group label="E-mail" label-for="email" class="mr-2 w-100">
            <b-form-input id="email"
              type="text"
              disabled
              v-model="form.email">
            </b-form-input>
          </b-form-group>
          <validation-provider
            name="Сотовый телефон"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="ml-2 mr-2 w-100"
          >
            <b-form-group label="Сотовый телефон *" label-for="phone">
              <b-form-input id="phone"
                type="text"
                v-model="form.phone"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="d-flex">
          <validation-provider
            name="Фамилия"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Фамилия" label-for="lastName" class="mr-3 w-100">
              <b-form-input id="lastName"
                type="text"
                v-model="form.lastName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Имя"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Имя" label-for="firstName" class="mr-3 w-100">
              <b-form-input id="firstName"
                type="text"
                v-model="form.firstName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Отчество"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Отчество" label-for="middleName" class="w-100">
              <b-form-input id="middleName"
                type="text"
                v-model="form.middleName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <b-form-group label="Пол" label-for="gender">
          <b-form-radio-group
            id="gender"
            v-model="form.gender"
            :options="genderOptions"
            buttons
            button-variant="light"
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group>

        <div class="d-flex">
          <validation-provider
            name="Регион"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="mr-2 w-100"
          >
            <b-form-group label="Регион *" label-for="parent" class="mr-3 w-100">
              <b-form-select v-model="form.region" class="" :state="getValidationState(validationContext)">
                <option :value="null" disabled>Выберите регион</option>
                <option v-for="option in regions" :value="option.id" :key="option.id">{{ option.title }}</option>
              </b-form-select>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Город"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="ml-2 w-100"
          >
            <b-form-group label="Город *" label-for="parent" class="mr-3 w-100">
              <b-form-select v-model="form.city" class="" :disabled="!form.region" :state="getValidationState(validationContext)">
                <option :value="null" disabled>Выберите город</option>
                <option v-for="option in filteredCities" :value="option.id" :key="option.id">{{ option.title }}</option>
              </b-form-select>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- <div class="d-flex">
          <validation-provider
            name="Улица"
            :rules="{ required: true }"
            v-slot="validationContext"
            class="w-100 mr-3"
          >
            <b-form-group label="Улица *" label-for="street">
              <b-form-input id="street"
                type="text"
                v-model="form.street"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Дом" class="mr-3" label-for="house">
            <b-form-input id="house"
              type="text"
              v-model="form.house">
            </b-form-input>
          </b-form-group>
          <b-form-group label="Квартира" label-for="apartment">
            <b-form-input id="apartment"
              type="text"
              v-model="form.apartment">
            </b-form-input>
          </b-form-group>
        </div> -->

        <div v-if="user.isAdmin">
          <h5 class="mt-5 mb-3 text-center">Задачи тренера</h5>

          <div class="d-flex">
            <b-form-group label="Мастер классы" label-for="masterClasses" class="w-100 mr-3">
              <b-form-input id="masterClasses"
                type="number"
                v-model="form.goalMasterClasses">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Ученики за год" label-for="studentsYear" class="w-100 ml-3">
              <b-form-input id="studentsYear"
                type="number"
                v-model="form.goalStudentsYear">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex">
            <b-form-group label="Ученики за I квартал" label-for="studentsQuarterOne" class="w-100 mr-3">
              <b-form-input id="studentsQuarterOne"
                type="number"
                v-model="form.goalStudentsQuarterOne">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Ученики за II квартал" label-for="studentsQuarterTwo" class="w-100 ml-3">
              <b-form-input id="studentsQuarterTwo"
                type="number"
                v-model="form.goalStudentsQuarterTwo">
              </b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex">
            <b-form-group label="Ученики за III квартал" label-for="studentsQuarterThree" class="w-100 mr-3">
              <b-form-input id="studentsQuarterThree"
                type="number"
                v-model="form.goalStudentsQuarterThree">
              </b-form-input>
            </b-form-group>

            <b-form-group label="Ученики за IV квартал" label-for="studentsQuarterFour" class="w-100 ml-3">
              <b-form-input id="studentsQuarterFour"
                type="number"
                v-model="form.goalStudentsQuarterFour">
              </b-form-input>
            </b-form-group>
          </div>
        </div>
        <b-button type="submit" variant="primary" size="lg" block>
          Сохранить
        </b-button>
        

        <!-- <b-form-group label="Тренер" label-for="isCoach">
          <b-form-radio-group
            id="isCoach"
            v-model="form.isCoach"
            :options="coachStatuses"
            buttons
            button-variant="light"
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group label="Студент" label-for="isStudent">
          <b-form-radio-group
            id="isStudent"
            v-model="form.isStudent"
            :options="studentStatuses"
            buttons
            button-variant="light"
            name="radios-btn-default"
          ></b-form-radio-group>
        </b-form-group> -->
      </b-form>
    </validation-observer>
    <b-button type="submit" variant="light" size="lg" block @click="hideModal" class="mt-2">
      Отмена
    </b-button>
  </b-modal>
</template>

<script>
import { slugify } from '@/services/utils'
import { mapState } from 'vuex'

export default {
  props: {
    item: [Object]
  },
  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        middleName: null,
        phone: null,
        gender: 'male',
        country: 'russia',
        region: null,
        // street: null,
        // house: null,
        // apartment: null,
        city: null,
        isCoach: null,
        isStudent: null,
        email: null,
        goalMasterClasses: 0,
        goalStudentsYear: 0,
        goalStudentsQuarterOne: 0,
        goalStudentsQuarterTwo: 0,
        goalStudentsQuarterThree: 0,
        goalStudentsQuarterFour: 0,
        onboardingComplete: true
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      countries: state => state.locations.countries,
      regions: state => state.locations.regions,
      cities: state => state.locations.cities,
      coachStatuses: state => state.auth.coachStatuses,
      genderOptions: state => state.auth.genderOptions,
      studentStatuses: state => state.auth.studentStatuses,
      groups: state => state.coaches.groups
    }),
    groupOptions () {
      return Object.keys(this.groups).map(group => {
        return { value: this.groups[group].slug, text: this.groups[group].title }
      }).sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
    },
    filteredCities () {
      return this.cities.filter((city) => {
        return city.region === this.form.region
      })
    },
    title () {
      if (this.item) {
        return 'Редактирование профиля'
      } else {
        return 'Создать тренера'
      }
    },
    btnTitle () {
      if (this.item) {
        return 'Сохранить'
      } else {
        return 'Создать'
      }
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    submit () {
      this.form.city = slugify(this.form.city)
      this.form.region = slugify(this.form.region)
      const { form } = this
      this.$store.dispatch('UPDATE_PROFILE', { form, id: this.item.id }).then((response) => {
        this.submitted = true
        this.$emit('update')
        this.$nextTick(() => {
          this.$refs.modal.hide()
        })
      }, error => {
        console.log(error)
      })
    },
    resetModal () {
      this.form.firstName = null
      this.form.lastName = null
      this.form.middleName = null
      this.form.gender = 'male'
      this.form.country = 'russia'
      this.form.region = null
      // this.form.street = null
      // this.form.house = null
      // this.form.apartment = null
      this.form.city = null
      this.form.isCoach = null
      this.form.isStudent = null
      this.form.email = null
      this.form.phone = null
      this.form.goalMasterClasses = 0
      this.form.goalStudentsYear = 0
      this.form.goalStudentsQuarterOne = 0
      this.form.goalStudentsQuarterTwo = 0
      this.form.goalStudentsQuarterThree = 0
      this.form.goalStudentsQuarterFour = 0
      this.form.onboardingComplete = true
    },
    showModal () {
      this.$refs['modal'].show()
    },
    hideModal () {
      this.$refs['modal'].hide()
    },
    toggleModal () {
      this.resetModal()
      if (this.item) {
        this.form.firstName = this.item.firstName
        this.form.lastName = this.item.lastName
        this.form.middleName = this.item.middleName
        this.form.gender = this.item.gender ? this.item.gender : 'male'
        this.form.country = this.item.country ? this.item.country : 'russia'
        this.form.region = this.item.region
        // this.form.street = this.item.street ? this.item.street : ''
        // this.form.house = this.item.house ? this.item.house : ''
        // this.form.apartment = this.item.apartment ? this.item.apartment : ''
        this.form.city = this.item.city
        this.form.isCoach = this.item.isCoach ? this.item.isCoach : false
        this.form.isStudent = this.item.isStudent ? this.item.isStudent : false
        this.form.email = this.item.email
        this.form.phone = this.item.phone ? this.item.phone : null
        this.form.goalMasterClasses = this.item.goalMasterClasses ? this.item.goalMasterClasses : 0
        this.form.goalStudentsYear = this.item.goalStudentsYear ? this.item.goalStudentsYear : 0
        this.form.goalStudentsQuarterOne = this.item.goalStudentsQuarterOne ? this.item.goalStudentsQuarterOne : 0
        this.form.goalStudentsQuarterTwo = this.item.goalStudentsQuarterTwo ? this.item.goalStudentsQuarterTwo : 0
        this.form.goalStudentsQuarterThree = this.item.goalStudentsQuarterThree ? this.item.goalStudentsQuarterThree : 0
        this.form.goalStudentsQuarterFour = this.item.goalStudentsQuarterFour ? this.item.goalStudentsQuarterFour : 0
        this.form.onboardingComplete = this.item.onboardingComplete ? this.item.onboardingComplete : true
      }
      this.$refs['modal'].show()
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
  }
}
</script>