<template>
  <div class="row">
    <div class="col-md-6">
      <div v-if="!coach">
        <h2>Выберите тренера</h2>
        <div v-if="coaches && !user.isPendingCoach">
          <coachCard :user="coach" v-for="coach in coaches" :key="coach.id" @select="select"></coachCard>
        </div>
      </div>
      <div v-else>
        <h2>Ваш тренер</h2>
        <div v-if="user.isPendingCoach">
          <coachCard :user="coach" :key="coach.id" :selected="true" :isPending="true"></coachCard>
        </div>
        <div v-else>
          <coachCard :user="coach" :key="coach.id" :selected="true" :isPending="false"></coachCard>
        </div>
      </div>
    </div>

    <div class="col-md-6" v-if="groups && groups.length > 0">
      <h2>Место тренировок</h2>
      <p v-html="groups[0].address"></p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoachCard from '../../Coach/Includes/CoachCardPlain'

export default {
  components: {
    coachCard: CoachCard
  },
  data () {
    return {
      form: {
        isPendingCoach: null,
        isStudent: true
      }
    }
  },
  computed: {
    ...mapState({
      coaches: state => state.managers.coaches,
      user: state => state.auth.user,
      coach: state => state.students.coach,
      groups: state => state.students.groups
    })
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    select (coachEmail) {
      this.form.isPendingCoach = coachEmail
      this.form.email = this.user.email
      const { form } = this
      this.$store.dispatch('UPDATE_PROFILE', { form, id: this.user.id }).then((response) => {
        this.$store.dispatch('GET_STUDENT_COACH', coachEmail).then(() => {
          this.$store.dispatch('ADD_STUDENT_TO_COACH', { coachEmail: coachEmail, studentId: this.user.id })
        })
      }, error => {
        console.log(error)
      })
    }
  },
  created () {
    if (this.user.city) {
      this.$store.dispatch('GET_COACHES_BY_CITY', this.user.city)
    }
    this.$store.dispatch('GET_STUDENT_COACH', this.user.coach)
  }
}
</script>