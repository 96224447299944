<template>
  <div class="container-fluid">
    <div class="row" v-if="coaches">
      <div class="col-md-3">
        <h1 class="xl">Тренеры <b-badge class="larger" variant="light">{{ coaches.length }}</b-badge></h1>
      </div>
      <div class="col-md-4 d-flex justify-content-center align-items-center">
        
      </div>
      <div class="col-md-5 d-flex align-items-start flex-row-reverse">
        <!-- <b-form-select v-model="selectedGroup" :options="groupOptions" value-field="id" options-field="a">
          <b-form-select-option :value="null" disabled>Выберите группу</b-form-select-option>
        </b-form-select> -->
        
        <b-form-select v-model="activeCountry" :options="countries" value-field="id" options-field="a">
          <template v-slot:first>
            <b-form-select-option :value="null">Все страны</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-select class="mr-2" v-model="activeRegion" :options="regions" value-field="id" options-field="a">
          <template v-slot:first>
            <b-form-select-option :value="null">Все регионы</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-select class="mr-2" v-model="activeCity" :options="cities" value-field="id" options-field="a">
          <template v-slot:first>
            <b-form-select-option :value="null">Все города</b-form-select-option>
          </template>
        </b-form-select>
      </div>
    </div>
    
    <div v-if="coaches">
      <coachCard :user="coach" v-for="coach in coaches" :key="coach.id"></coachCard>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoachCard from '../Coach/Includes/CoachCard'
import { objectToArraySafe } from '@/services/utils'

export default {
  name: 'coaches',
  components: {
    coachCard: CoachCard
  },
  data () {
    return {
      activeCountry: null,
      activeCity: null,
      activeRegion: null
    }
  },
  computed: {
    ...mapState({
      countries: state => objectToArraySafe(state.locations.countries),
      cities: state => objectToArraySafe(state.locations.cities),
      regions: state => objectToArraySafe(state.locations.regions),
      coaches: state => state.managers.coaches
    })
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
    this.$store.dispatch('GET_COACHES')
    this.$store.dispatch('GET_CITIES')
    this.$store.dispatch('GET_REGIONS')
    this.$store.dispatch('GET_COUNTRIES')
  }
}
</script>