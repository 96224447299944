<template>
  <header class="" v-if="$route.name !== 'base' && isLogged">
    
    <nav class="navbar fixed-top flex-md-nowrap p-0">
      <router-link :to="{ name: 'profile' }" class="pl-3 brand">
        <img height="50" src="/static/images/logo.svg" alt="KyokushinProfi.com">
      </router-link>

      <div>
        <router-link :to="{ name: 'profile' }" class="router-link" v-if="!user.isCupManager">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg> 
          Тренировки
        </router-link>
        <router-link :to="{ name: 'students' }" class="router-link" v-if="user.isCoach">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> 
          Ученики
        </router-link>
        <router-link :to="{ name: 'knowledge' }" class="router-link" v-if="user.isCoach">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg> 
          Поддержка
        </router-link>
        <router-link :to="{ name: 'coaches' }" class="router-link" v-if="user.isManager && !user.isCupManager">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> 
          Тренеры
        </router-link>
        <router-link :to="{ name: 'cup' }" class="router-link" v-if="user.isCupManager">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg> 
          Заявки на Турнир
        </router-link>
        <router-link :to="{ name: 'help' }" class="router-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> 
          Помощь
        </router-link>
        <router-link v-if="user.isAdmin" :to="{ name: 'admin' }" class="router-link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
          Админ</router-link>
        
      </div>
      <a class="" href="#" @click.prevent="logout()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
          Выход</a>
      
      <!-- <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap">
          <div class="d-flex align-items-center cursor-pointer" v-if="user" @click="$bvModal.show('header-modal')">
            <b-avatar size="3rem" class="mr-2" variant="danger" src="/static/images/avatar.jpg"></b-avatar>
            <div>
              <p class="mb-2 lh1">{{ user.firstName }}</p>
              <p class="mb-0 lh1 text-muted">
                <span v-if="user.isCoach">Тренер</span>
                <span v-else-if="user.isStudent">Ученик</span>
                <span v-else-if="user.isAdmin">Админ</span>
                <span v-else-if="user.isManager">Менеджер</span>
              </p>
            </div>
          </div>
          <div class="header__account">
            <b-modal id="header-modal" hide-footer hide-header hide-backdrop static lazy>
              <div class="header-modal bg-white">
                <div class="modal-content">
                  <div class="modal-header text-left">
                    <button @click="$bvModal.hide('header-modal')" type="button" class="close">
                      <span aria-hidden="true">×</span>
                    </button>
                    <div class="header-modal-top">
                      <div class="d-flex">
                        <b-avatar size="3rem" class="mr-2" variant="danger" src="/static/images/avatar.jpg"></b-avatar>
                        <div>
                          <div class="modal-title">
                            <span>{{ user.firstName }} {{ user.lastName }}</span>
                          </div>
                          <p class="mb-0 lh1 text-muted">
                            <span v-if="user.isCoach">Тренер</span>
                            <span v-else-if="user.isStudent">Ученик</span>
                            <span v-else-if="user.isAdmin">Админ</span>
                            <span v-else-if="user.isManager">Менеджер</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-body">
                    <ul class="modal-menu">
                      <li><router-link :to="{ name: 'profile' }" class="px-0">Мой профиль</router-link></li>
                      <li><router-link :to="{ name: 'profile' }" class="px-0">Тренировки</router-link></li>
                      <li><router-link :to="{ name: 'help' }" class="px-0">Помощь</router-link></li>
                      <li v-if="user.isAdmin"><router-link :to="{ name: 'admin' }" class="px-0">Админ</router-link></li>
                    </ul>
                    <a class="px-0" href="#" @click.prevent="logout()">Выйти из аккаунта</a>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
        </li>
      </ul> -->
    </nav>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'app-header',
  data () {
    return {
      showAccountModal: false
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('LOGOUT')
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    isLogged () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user.id
      }
    }
  },
  created () {
  },
  watch: {
    '$route' () {
      this.$bvModal.hide('header-modal')
    }
  }
}
</script>