<template>
  <div class="container-fluid">
    <hr class="space space-sm">
    <h1 class="xl">Все заявки на Кубок Содружеств 2021</h1>

    <table class="table table-bordered" v-if="applicants && applicants.length > 0">
      <thead>
        <th>No.</th>
        <th>Фамилия, имя</th>
        <th>Дата рождения</th>
        <th>Возраст (на момент кубка)</th>
        <th>Пол</th>
        <th>Кю/дан</th>
        <th>Вес</th>
        <th>Категория</th>
        <th>Субъект</th>
        <th>Тренер</th>
      </thead>
      <tbody>
        <tr v-for="(applicant, index) in applicants" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ applicant.lastName }} {{ applicant.firstName }}</td>
          <td><span v-if="applicant.birthday">{{ applicant.birthday }}</span><span v-else>{{ applicant.year }}-{{ applicant.month }}-{{ applicant.day }}</span></td>
          <td>{{ getAge(applicant) }}</td>
          <td><span v-if="applicant.gender == 'female'">Ж</span><span v-else>М</span></td>
          <td>{{ applicant.danFighter }}</td>
          <td>{{ applicant.weight }} кг</td>
          <td>{{ getCategory(applicant) }}</td>
          <td>{{ applicant.city }}</td>
          <td>{{ applicant.coachName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'cup',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      applicants: state => state.cup.applicants
    })
  },
  methods: {
    getAge (applicant) {
      if (applicant.birthday) {
        return Number(this.$moment('27/02/2021', 'DD/MM/YYYY').diff(applicant.birthday, 'years', false))
      } else {
        let birthday = applicant.year + '-' + applicant.month + '-' + applicant.day
        return Number(this.$moment('27/02/2021', 'DD/MM/YYYY').diff(birthday, 'years', false))
      }
    },
    getCategory (applicant) {
      if (applicant.gender === 'male') {
        if (this.getAge(applicant) === 10 || this.getAge(applicant) === 11) {
          if (applicant.weight < 30) {
            return 'До 30 кг'
          } else if (applicant.weight < 35) {
            return 'До 35 кг'
          } else if (applicant.weight < 40) {
            return 'До 40 кг'
          } else if (applicant.weight < 45) {
            return 'До 45 кг'
          } else if (applicant.weight < 50) {
            return 'До 50 кг'
          } else if (applicant.weight >= 50) {
            return 'Свыше 50 кг'
          }
        }

        if (this.getAge(applicant) === 12 || this.getAge(applicant) === 13) {
          if (applicant.weight < 35) {
            return 'До 35 кг'
          } else if (applicant.weight < 40) {
            return 'До 40 кг'
          } else if (applicant.weight < 45) {
            return 'До 45 кг'
          } else if (applicant.weight < 50) {
            return 'До 50 кг'
          } else if (applicant.weight < 55) {
            return 'До 55 кг'
          } else if (applicant.weight >= 55) {
            return 'Свыше 55 кг'
          }
        }

        if (this.getAge(applicant) === 14 || this.getAge(applicant) === 15) {
          if (applicant.weight < 45) {
            return 'До 45 кг'
          } else if (applicant.weight < 50) {
            return 'До 50 кг'
          } else if (applicant.weight < 55) {
            return 'До 55 кг'
          } else if (applicant.weight < 60) {
            return 'До 60 кг'
          } else if (applicant.weight >= 65) {
            return 'Свыше 65 кг'
          }
        }

        if (this.getAge(applicant) === 16 || this.getAge(applicant) === 17) {
          if (applicant.weight < 55) {
            return 'До 55 кг'
          } else if (applicant.weight < 60) {
            return 'До 60 кг'
          } else if (applicant.weight < 65) {
            return 'До 65 кг'
          } else if (applicant.weight < 70) {
            return 'До 70 кг'
          } else if (applicant.weight >= 75) {
            return 'Свыше 75 кг'
          }
        }

        if (this.getAge(applicant) > 17 && this.getAge(applicant) < 35) {
          if (applicant.weight < 65) {
            return 'До 65 кг'
          } else if (applicant.weight < 70) {
            return 'До 70 кг'
          } else if (applicant.weight < 80) {
            return 'До 80 кг'
          } else if (applicant.weight >= 80) {
            return 'Свыше 80 кг'
          }
        }

        if (this.getAge(applicant) >= 35) {
          if (applicant.weight < 70) {
            return 'До 70 кг'
          } else if (applicant.weight < 80) {
            return 'До 80 кг'
          } else if (applicant.weight >= 80) {
            return 'Свыше 80 кг'
          }
        }
      } else { // girls
        if (this.getAge(applicant) === 10 || this.getAge(applicant) === 11) {
          if (applicant.weight < 30) {
            return 'До 30 кг'
          } else if (applicant.weight < 40) {
            return 'До 40 кг'
          } else if (applicant.weight >= 40) {
            return 'Свыше 40 кг'
          }
        }

        if (this.getAge(applicant) === 12 || this.getAge(applicant) === 13) {
          if (applicant.weight < 40) {
            return 'До 40 кг'
          } else if (applicant.weight < 50) {
            return 'До 50 кг'
          } else if (applicant.weight >= 50) {
            return 'Свыше 50 кг'
          }
        }

        if (this.getAge(applicant) === 14 || this.getAge(applicant) === 15) {
          if (applicant.weight < 50) {
            return 'До 50 кг'
          } else if (applicant.weight < 60) {
            return 'До 60 кг'
          } else if (applicant.weight >= 60) {
            return 'Свыше 60 кг'
          }
        }

        if (this.getAge(applicant) === 16 || this.getAge(applicant) === 17) {
          if (applicant.weight < 55) {
            return 'До 55 кг'
          } else if (applicant.weight < 65) {
            return 'До 65 кг'
          } else if (applicant.weight >= 65) {
            return 'Свыше 65 кг'
          }
        }

        if (this.getAge(applicant) > 17 && this.getAge(applicant) < 35) {
          if (applicant.weight < 55) {
            return 'До 55 кг'
          } else if (applicant.weight < 65) {
            return 'До 65 кг'
          } else if (applicant.weight >= 65) {
            return 'Свыше 65 кг'
          }
        }

        if (this.getAge(applicant) >= 35) {
          if (applicant.weight < 70) {
            return 'До 70 кг'
          } else if (applicant.weight < 80) {
            return 'До 80 кг'
          } else if (applicant.weight >= 80) {
            return 'Свыше 80 кг'
          }
        }
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
    this.$store.dispatch('GET_CUP_APPLICANTS')
    this.$store.dispatch('GET_USER')

    if (!this.user.isCupManager) {
      this.$router.push({ name: 'profile' })
    }
  }
}
</script>