import firebase from 'firebase/app'

const db = firebase.firestore()

const state = {
  cities: [],
  regions: [],
  countries: []
}

const actions = {
  GET_CITIES ({ commit }) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('cities')
      let unsub = ref.onSnapshot(snapshot => {
        let cities = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          cities.push(log)
        })
        commit('SET_LOADED_CITIES', cities)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  },
  GET_REGIONS ({ commit }) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('regions')
      let unsub = ref.onSnapshot(snapshot => {
        let regions = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          regions.push(log)
        })
        commit('SET_LOADED_REGIONS', regions)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  },
  GET_COUNTRIES ({ commit }) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('countries')
      let unsub = ref.onSnapshot(snapshot => {
        let countries = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          countries.push(log)
        })
        commit('SET_LOADED_COUNTRIES', countries)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  },
  CREATE_CITY ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let data = payload.city
      db.collection('cities')
        .doc(String(data['slug']))
        .set(data)
        .then(snapshot => {
          resolve(snapshot)
        })
    })
  },
  CREATE_COUNTRY ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let data = payload.country
      db.collection('countries')
        .doc(String(data['slug']))
        .set(data)
        .then(snapshot => {
          resolve(snapshot)
        })
    })
  },
  CREATE_REGION ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let data = payload.region
      db.collection('regions')
        .doc(String(data['slug']))
        .set(data)
        .then(snapshot => {
          resolve(snapshot)
        })
    })
  }
}

const getters = {
  getCountries (state) {
    return state.countries
  },
  getRegions (state) {
    return state.regions
  }
}

const mutations = {
  SET_LOADED_CITIES (state, payload) {
    state.cities = payload
  },
  SET_LOADED_REGIONS (state, payload) {
    state.regions = payload
  },
  SET_LOADED_COUNTRIES (state, payload) {
    state.countries = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
