<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <h2>Журнал</h2>
      </div>
      <div class="col-md-6 d-flex justify-content-center align-items-center" v-if="generatedMonth">
        <a class="px-3" href="#" @click.prevent="displayPrevMonth()"><img src="/static/images/icons/chevron_left.svg" alt=""></a>
        <div class="lead text-capitalize">{{ generatedMonth[0] | moment('MMMM Y') }}</div>
        <a class="px-3 btn btn-link no-shadow" href="#" @click.prevent="displayNextMonth()" :class="{ 'disabled': isNextOutOfScope() }"><img src="/static/images/icons/chevron_right.svg" alt=""></a>
      </div>
      <div class="col-md-3 text-md-right">
        <b-form-select v-model="activeGroup" :options="groups" value-field="id" options-field="a">
          <template v-slot:first>
            <b-form-select-option :value="null">Все группы</b-form-select-option>
          </template>
        </b-form-select>
      </div>
    </div>
    <br>

    <b-overlay :show="show" rounded="sm">
      <div class="row" v-if="students">
        <div class="col-md-3">
          <b-form-checkbox class="d-inline-block lh1" v-model="allSelected" @change="toggleAll" name="check-user" size="lg"></b-form-checkbox>
          <p class="text-secondary normal d-inline-block"><span class="mr-2">No.</span> Имя, фамилия</p>
          <p v-for="(student, index) in students" :key="index">
            <b-form-checkbox class="d-inline-block py-0 lh1" v-model="selectedStudents" :key="student.id" :value="student" name="check-user" size="lg"></b-form-checkbox> 
            <span class="text-muted mr-4">{{ index + 1 }}</span> {{ student.firstName }} {{ student.lastName }} <b-badge class="small normal" variant="light">{{ getTotalAttendanceForMonthForStudent(student) }}</b-badge>
          </p>
          <p v-if="selectedStudents && selectedStudents.length > 0">
            <a href="#" class="btn btn-primary btn-sm btn-block" @click.prevent="markDaysActive">Присутствует</a>
          </p>
          <!-- <p class="lead">&nbsp;</p> -->
          <hr>
          <p>Присутствовало <b-badge class="small normal" variant="light">{{ getTotalAttendanceForMonth() }}</b-badge></p>
          <p>Продолжительность</p>
          <p class="text-muted">Дополнительные поля <img class="ml-2" src="/static/images/icons/chevron_down.svg" alt=""></p>
        </div>

        <div class="col-md-9">
          <div class="d-flex justify-content-between">
            <div class="text-center"
              v-for="(day, index) in generatedMonth"
              :key="index"
              :class="{ 'text-muted': isBefore(day) }">
              <p class="normal" :class="{ 'bg-primary text-white px-1 rounded': isToday(day), 'bg-lighter': isWeekend(day) }">{{ day | moment('D') }}</p>
              <div v-if="!isAfter(day)">
                <p v-for="(student, index) in students" :key="index" :class="{ 'bg-lighter': isWeekend(day) }" class="cursor-pointer cursor-hover-primary" @click.prevent="toggleAttendance(student, day)">
                  <img v-if="isStudentAttended(student, day) && !isToday(day)" src="/static/images/icons/tick.svg" alt="">
                  <img v-if="isStudentAttended(student, day) && isToday(day)" src="/static/images/icons/tick_active.svg" alt="">
                  <img v-if="!isStudentAttended(student, day) && !isToday(day)" src="/static/images/icons/cross.svg" alt="">
                  <img v-if="!isStudentAttended(student, day) && isToday(day)" src="/static/images/icons/cross_active.svg" alt="">
                </p>
              </div>
              <div v-else>
                <p v-for="(student, index) in students" :key="index">&nbsp;</p>
              </div>
              <!-- <p class="lead">&nbsp;</p> -->
              <hr>
              <p v-if="!isAfter(day)" :class="{ 'bg-lighter': isWeekend(day) }">
                {{ getTotalAttendance(day) }}
              </p>
              <p v-if="!isAfter(day)" :class="{ 'bg-lighter': isWeekend(day) }">
                {{ getTotalAttendance(day) * 90 }}
              </p>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-1 text-right">
          <p>&nbsp;</p>
          <p v-for="(student, index) in students" :key="index">{{ getTotalAttendanceForMonthForStudent(student) }}</p>
          <!- <p class="lead">Итого {{ getTotalAttendanceForMonth() * 100 | currency('₽', 2) }}</p> -->
          <!-- <hr>
          <p>{{ getTotalAttendanceForMonth() }}</p>
          <p>&nbsp;</p>
        </div> -->
      </div>
      <div v-else class="border rounded p-3 py-5 text-center text-muted">
        <h5 class="text-muted">Журнал пуст</h5>
        <p class="mb-0">У вас еще нет групп и учеников, поэтому журнал пустует.<br>
        Чтобы добавить группы или учеников, перейдите в раздел  <router-link :to="{ name: 'students' }">Ученики</router-link>.</p>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { objectToArraySafe } from '@/services/utils'
export default {
  components: {
  },
  data () {
    return {
      show: true,
      generatedMonth: null,
      timeTracker: this.$moment(),
      today: this.$moment().date(),
      activeGroup: null,
      allSelected: [],
      selectedStudents: [],
      options: [
        { value: null, text: 'Группа' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      groups: state => objectToArraySafe(state.coaches.groups),
      students: state => state.coaches.students,
      journal: state => state.coaches.journal
    })
  },
  methods: {
    toggleAll (checked) {
      this.selectedStudents = checked ? this.students : []
    },
    getTotalAttendanceForMonthForStudent (student) {
      let attendance = 0

      if (this.generatedMonth) {
        this.generatedMonth.forEach((day) => {
          let monthNumber = this.$moment(day).format('MM')
          let dayNumber = this.$moment(day).format('DD')
          if (this.dayExistsInJournal(day)) {
            if (this.journal[monthNumber][dayNumber].students.includes(student.id)) {
              attendance++
            }
          }
        })
      }
      return attendance
    },
    getTotalAttendanceForMonth () {
      let attendance = 0

      if (this.generatedMonth) {
        this.generatedMonth.forEach((day) => {
          let monthNumber = this.$moment(day).format('MM')
          let dayNumber = this.$moment(day).format('DD')
          if (this.dayExistsInJournal(day)) {
            attendance = attendance + this.journal[monthNumber][dayNumber].students.length
          }
        })
      }
      return attendance
    },
    getTotalAttendance (day) {
      let monthNumber = this.$moment(day).format('MM')
      let dayNumber = this.$moment(day).format('DD')
      let attendance = 0
      if (this.dayExistsInJournal(day)) {
        if (this.journal[monthNumber][dayNumber].students) {
          return this.journal[monthNumber][dayNumber].students.length
        }
      }
      return attendance
    },
    isStudentAttended (student, day) {
      let monthNumber = this.$moment(day).format('MM')
      let dayNumber = this.$moment(day).format('DD')

      if (this.dayExistsInJournal(day)) {
        if (this.journal[monthNumber][dayNumber].students.includes(student.id)) {
          return true
        }
      }
      return false
    },
    dayExistsInJournal (day) {
      let monthNumber = this.$moment(day).format('MM')
      let dayNumber = this.$moment(day).format('DD')

      if (this.journal) {
        if (this.journal[monthNumber]) {
          if (this.journal[monthNumber][dayNumber]) {
            return true
          }
        }
      }
    },
    toggleAttendance (student, day) {
      this.show = true
      if (this.isStudentAttended(student, day)) {
        this.$store.dispatch('REMOVE_FROM_JOURNAL', { student, day }).then((response) => {
          this.$store.dispatch('GET_JOURNAL', this.$moment().format('YYYY'))
          this.show = false
        })
      } else {
        this.$store.dispatch('ADD_TO_JOURNAL', { student, day }).then((response) => {
          this.$store.dispatch('GET_JOURNAL', this.$moment().format('YYYY'))
          this.show = false
        })
      }
    },
    getMonth (e) {
      if (e === 0) {
        this.timeTracker = this.$moment()
      } else {
        this.timeTracker.add(e, 'month')
      }

      var daysInMonth = this.timeTracker.clone().daysInMonth()
      var arrDays = []

      while (daysInMonth) {
        if (e) {
          arrDays.push(this.timeTracker.clone().date(daysInMonth))
        } else {
          arrDays.push(this.timeTracker.clone().date(daysInMonth))
          // if (daysInMonth <= this.today) {
          //   arrDays.push(this.timeTracker.clone().date(daysInMonth))
          // }
        }
        daysInMonth--
      }

      setTimeout(() => {
        this.show = false
      }, 100)

      return arrDays.reverse()
    },
    getMonthDaysUntilToday () {
      var daysInMonth = this.$moment().clone().daysInMonth()
      var arrDays = []

      while (daysInMonth) {
        if (daysInMonth <= this.today) {
          arrDays.push(this.timeTracker.clone().date(daysInMonth))
        }
        daysInMonth--
      }
      return arrDays.reverse()
    },
    displayPrevMonth () {
      this.show = true
      this.generatedMonth = this.getMonth(-1)
    },
    displayNextMonth () {
      this.show = true
      this.generatedMonth = this.getMonth(1)
    },
    displayCurrentMonth () {
      this.show = true
      this.generatedMonth = this.getMonth()
    },
    dateToNumber (day) {
      return this.$moment(day).format('D')
    },
    isBefore (day) {
      return this.$moment(day).isBefore(this.$moment(), 'day')
    },
    isAfter (day) {
      return this.$moment(day).isAfter(this.$moment(), 'day')
    },
    isSameOrAfter (day) {
      return this.$moment(day).isSameOrAfter(this.$moment().startOf('month'), 'day')
    },
    isSameOrBefore (day) {
      return this.$moment(day).isSameOrBefore(this.$moment(), 'day')
    },
    isToday (day) {
      return this.$moment(day).isSame(new Date(), 'day')
    },
    isWeekend (day) {
      let dayNumber = this.$moment(day).day()
      return (dayNumber === 6) || (dayNumber === 0)
    },
    isNextOutOfScope () {
      let thisWeekNumber = this.$moment().month()
      let selectedWeekNumber = this.$moment(this.generatedMonth[0]).month()
      let diff = selectedWeekNumber - thisWeekNumber
      // let isSameWeek = this.$moment(this.generatedWeek[0]).isSame(new Date(), 'week')
      if (diff > 0) {
        return true
      }
    },
    markDaysActive () {
      this.show = true

      this.selectedStudents.forEach((student) => {
        if (this.getMonthDaysUntilToday()) {
          this.getMonthDaysUntilToday().forEach((day) => {
            if (!this.isStudentAttended(student, day)) {
              this.$store.dispatch('ADD_TO_JOURNAL', { student, day }).then((response) => {
                this.$store.dispatch('GET_JOURNAL', this.$moment().format('YYYY'))
                this.show = false
              })
            } else {
              this.show = false
            }
          })
        }
      })
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.displayCurrentMonth()

    if (this.students) {
      this.students.forEach((student) => {
        this.markDaysActive(student)
      })
    }

    setTimeout(() => {
      this.show = false
    }, 2000)
  },
  created () {
    this.$store.dispatch('GET_JOURNAL', this.$moment().format('YYYY'))
  },
  watch: {
    activeGroup (value) {
      this.show = true
      if (value) {
        this.$store.dispatch('GET_STUDENTS_BY_GROUP', { students: this.user.students, group: value }).then(() => {
          this.show = false
        })
      } else {
        this.$store.dispatch('GET_USER').then(() => {
          this.$store.dispatch('GET_COACH_STUDENTS', this.user.students).then(() => {
            this.show = false
          })
        })
      }
    }
  }
}
</script>