<template>
  <div class="d-flex justify-content-start align-items-center">
    <div>
      <b-avatar size="5rem" class="mr-2" variant="light" src="/static/images/avatar.svg"></b-avatar>
    </div>
    <div class="w-100 ml-md-2">
      <div class="d-flex justify-content-between">
        <div>
          <h5>{{ user.firstName }} {{ user.lastName }}</h5>
          <p class="large text-secondary mb-0">{{ user.city | humanizeCity(cities) }} {{ user.city | getCityRegion(cities, regions) }} {{ user.country | humanizeCountry(countries) }}</p>
        </div>
        <div>
          <a :href="'tel:' + user.phone " class="btn btn-danger" v-if="selected && !isPending">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg> 
              Whatsapp
          </a>
          <a href="#" @click.prevent="selectCoach(user.email)" class="btn btn-danger" v-if="!selected">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg> 
              Выбрать
          </a>
          <a href="#" disabled class="btn btn-danger btn-disabled" v-if="isPending">
            На одобрении у тренера
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    user: Object,
    selected: Boolean,
    isPending: Boolean
  },
  components: {
  },
  data () {
    return {
      show: true,
      year: this.$moment().year(),
      quarter: this.$moment().quarter()
    }
  },
  computed: {
    ...mapState({
      cities: state => state.locations.cities,
      regions: state => state.locations.regions,
      countries: state => state.locations.countries,
      isLoading: state => state.auth.loading
    })
  },
  methods: {
    selectCoach (coachEmail) {
      this.$emit('select', coachEmail)
    },
    update () {
      this.$store.dispatch('GET_GROUPS')
      this.$refs.modal.hideModal()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('SET_LOADING', false)

    setTimeout(() => {
      this.show = false
    }, 2000)
  },
  created () {
    this.$store.dispatch('SET_LOADING', true)
    this.$store.dispatch('GET_CITIES')
    this.$store.dispatch('GET_REGIONS')
    this.$store.dispatch('GET_COUNTRIES')
    this.$store.dispatch('GET_GROUPS')
  }
}
</script>