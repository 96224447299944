import Input from '@/components/UIComponents/Inputs/Input'
import Select from '@/components/UIComponents/Inputs/Select'
import Progress from '@/components/UIComponents/Progress'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component('v-input', Input)
    Vue.component('v-select', Select)
    Vue.component('v-progress', Progress)
  }
}

export default GlobalComponents
