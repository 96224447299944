<template>
  <div class="container-fluid">
    <h1 class="xl">База знаний</h1>
  </div>
</template>

<script>
export default {
  name: 'knowledge',
  data () {
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>