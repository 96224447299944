<template>
  <div class="container-fluid">
    <h1 class="xl">Администрация</h1>

    <div class="row">
      <div class="col-md-4">
        <h3 class="mb-4">Страны</h3>
        <div class="table-responsive">
          <table class="table" v-if="countries">
            <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="country in countries" :key="country.id">
                <td>{{ country.id }}</td>
                <td>{{ country.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <a href="#" @click.prevent="openCountryEditModal(false)" class="btn btn-primary">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
          Добавить страну
        </a>
      </div>

      <div class="col-md-4">
        <h3 class="mb-4">Регионы</h3>
        <div class="table-responsive">
          <table class="table" v-if="regions">
            <thead>
              <tr>
                <th>ID</th>
                <th>Страна</th>
                <th>Название</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="region in regions" :key="region.id">
                <td>{{ region.id }}</td>
                <td>{{ region.country }}</td>
                <td>{{ region.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <a href="#" @click.prevent="openRegionEditModal(false)" class="btn btn-primary">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
          Добавить регион
        </a>
      </div>

      <div class="col-md-4">
        <h3 class="mb-4">Города</h3>
        <div class="table-responsive">
          <table class="table" v-if="cities">
            <thead>
              <tr>
                <th>ID</th>
                <th>Страна</th>
                <th>Регион</th>
                <th>Название</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="city in cities" :key="city.id">
                <td>{{ city.id }}</td>
                <td>{{ city.country }}</td>
                <td>{{ city.region }}</td>
                <td>{{ city.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <a href="#" @click.prevent="openEditModal(false)" class="btn btn-primary">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
          Добавить город
        </a>
      </div>
    </div>

    <city-add ref="modal" @update="update"></city-add>
    <country-add ref="countryModal" @update="updateCountry"></country-add>
    <region-add ref="regionModal" @update="updateRegion"></region-add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CityAdd from './CityAddModal'
import CountryAdd from './CountryAddModal'
import RegionAdd from './RegionAddModal'

export default {
  name: 'admin',
  components: {
    cityAdd: CityAdd,
    countryAdd: CountryAdd,
    regionAdd: RegionAdd
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      countries: state => state.locations.countries,
      cities: state => state.locations.cities,
      regions: state => state.locations.regions
    })
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    openEditModal (item) {
      if (item) {
        this.editGroup = item
        setTimeout(() => {
          this.$refs.modal.showModal()
        }, 100)
      } else {
        this.editGroup = null
        setTimeout(() => {
          this.$refs.modal.showModal()
        }, 100)
      }
    },
    update () {
      // this.$store.dispatch('GET_GROUPS')
      this.$refs.modal.hideModal()
    },
    openCountryEditModal (item) {
      if (item) {
        this.editGroup = item
        setTimeout(() => {
          this.$refs.countryModal.showModal()
        }, 100)
      } else {
        this.editGroup = null
        setTimeout(() => {
          this.$refs.countryModal.showModal()
        }, 100)
      }
    },
    updateCountry () {
      // this.$store.dispatch('GET_GROUPS')
      this.$refs.countryModal.hideModal()
    },
    openRegionEditModal (item) {
      if (item) {
        this.editGroup = item
        setTimeout(() => {
          this.$refs.regionModal.showModal()
        }, 100)
      } else {
        this.editGroup = null
        setTimeout(() => {
          this.$refs.regionModal.showModal()
        }, 100)
      }
    },
    updateRegion () {
      // this.$store.dispatch('GET_GROUPS')
      this.$refs.regionModal.hideModal()
    }
  },
  created () {
    this.$store.dispatch('GET_CITIES')
    this.$store.dispatch('GET_REGIONS')
    this.$store.dispatch('GET_COUNTRIES')
    this.$store.dispatch('GET_USER')

    if (!this.user.isAdmin) {
      this.$router.push({ name: 'profile' })
    }
  }
}
</script>