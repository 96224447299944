<template>
  <div class="login">
    <div class="container">
      <br class="d-none d-md-block"><br class="d-none d-md-block">
      <div class="text-center" v-if="isLoading">
        <b-spinner variant="primary" label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <div class="row mt-5">
          <div class="col-md-6 offset-md-3">
            <div class="card shadow-xs no-border radius10">
              <div class="card-body">
                <p class="text-center mt-3"><router-link :to="{ name: 'base' }"><img src="/static/images/logo.svg" alt=""></router-link></p>
                <h1 class="h3 text-center mb-4">Восстановить пароль</h1>
                
                <form class="text-center px-md-5">
                  <div class="form-group text-left" :class="{'input': true }">
                    <input
                      class="form-control form-control-lg"
                      required
                      type="text"
                      name="email"
                      v-model="email"
                      placeholder="E-mail">
                  </div>
                  <vue-recaptcha
                    ref="invisibleRecaptcha"
                    sitekey="6Lde0-YUAAAAAFrFCIUommtp49SMEDtmmFK5sM1u"
                    @verify="onVerify"
                    @expired="onExpired"
                    v-if="!isLoading"
                  >
                    <button type="submit" class="btn btn-primary btn-block" :disabled="isLoading">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                      Запросить сброс пароля</button>
                  </vue-recaptcha>
                  <br>
                  <div class="text-danger" v-if="submitted">
                    <p>Ссылка на восстановление пароля отправлена</p>
                  </div>
                  <div class="text-danger" v-if="error">
                    <p v-if="error.code = 'auth/wrong-password'">Неверный пароль</p>
                    <p v-else>{{ error.message }}</p>
                  </div>
                </form>
                <p class="text-center">Или <router-link :to="{ name: 'login' }">вернуться на страницу входа</router-link></p>      
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import firebase from '@/firebase'
  import VueRecaptcha from 'vue-recaptcha'
  export default {
    name: 'reset',
    components: { VueRecaptcha },
    data () {
      return {
        isLoading: null,
        email: '',
        submitted: null
      }
    },
    computed: {
      user () {
        return this.$store.getters.getUser
      },
      error () {
        return this.$store.getters.getError
      }
    },
    mounted () {
      this.isLoading = true
      this.$store.dispatch('SET_LOADING', true)
      setTimeout(() => {
        this.isLoading = false
        this.$store.dispatch('SET_LOADING', false)
      }, 1000)

      if (this.user !== null && this.user !== undefined) {
        this.$router.push({ name: 'profile' })
      }

      let el = document.body
      el.classList.add('bg-light')
    },
    methods: {
      reset () {
        const user = {
          email: this.email
        }
        this.$store.dispatch('RESET_PASSWORD', user).then(() => {
          this.submitted = true
        })
      },
      onSubmit () {
        this.$refs.invisibleRecaptcha.execute()
      },
      onVerify (response) {
        this.isLoading = true
        this.EventHolder.$emit('startLoading')
        if (response) {
          this.isLoading = false
          this.EventHolder.$emit('stopLoading')
          if (this.email) {
            this.reset()
          }
        }
        this.$refs.invisibleRecaptcha.reset()
      },
      onExpired () {
        console.log('Expired')
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset() // Direct call reset method
      }
    },
    created () {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.$store.dispatch('AUTO_SIGN_IN', user)
        }
      })
    },
    destroyed () {
      let el = document.body
      el.classList.remove('bg-light')
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push({ name: 'profile' })
        }
      }
    }
  }
</script>