<template>
  <b-modal 
    id="modal-1"
    ref="modal"
    :title="title"
    @show="toggleModal"
    @hidden="resetModal"
    @ok="handleOk"
    hide-footer
    :ok-title="btnName"
    cancel-title="Отменить"
    cancel-variant="light"
    footer-class="justify-content-between"
  >
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form ref="form" @submit.stop.prevent="handleSubmit(submit)">
        <validation-provider
          name="Название группы"
          rules="required"
          v-slot="validationContext"
          class="mr-3"
        >
          <b-form-group label="Название группы" label-for="title">
            <b-form-input
              id="title"
              v-model="group.title"
              placeholder=""
              :state="getValidationState(validationContext)">
            </b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Адрес зала"
          rules="required"
          v-slot="validationContext"
          class="mr-3"
        >
          <b-form-group label="Адрес зала" label-for="address">
            <b-form-textarea
              id="address"
              v-model="group.address"
              placeholder=""
              rows="2"
              max-rows="4"
              :state="getValidationState(validationContext)">
            </b-form-textarea>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <label>Расписание тренировок</label>

        <p><b-form-checkbox size="lg" v-model="group.schedule.monday.isActive">Понедельник</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.monday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.monday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.monday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <p><b-form-checkbox size="lg" v-model="group.schedule.tuesday.isActive">Вторник</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.tuesday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.tuesday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.tuesday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <p><b-form-checkbox size="lg" v-model="group.schedule.wednesday.isActive">Среда</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.wednesday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.wednesday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.wednesday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <p><b-form-checkbox size="lg" v-model="group.schedule.thursday.isActive">Четверг</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.thursday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.thursday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.thursday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <p><b-form-checkbox size="lg" v-model="group.schedule.friday.isActive">Пятница</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.friday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.friday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.friday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <p><b-form-checkbox size="lg" v-model="group.schedule.saturday.isActive">Суббота</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.saturday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.saturday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.saturday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <p><b-form-checkbox size="lg" v-model="group.schedule.sunday.isActive">Воскресенье</b-form-checkbox></p>
        <div class="d-flex mb-3 align-items-center" v-if="group.schedule.sunday.isActive">
          <b-form-timepicker placeholder="Начало" locale="ru" v-model="group.schedule.sunday.from" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
          <div class="mx-2">-</div>
          <b-form-timepicker placeholder="Завершение" locale="ru" v-model="group.schedule.sunday.to" minutes-step="5" label-no-time-selected="Укажите время" label-close-button="Отмена"></b-form-timepicker>
        </div>

        <!-- <b-form-group label="Статус" label-for="status">
          <b-form-select v-model="group.isActive" :options="statusOptions" class="mb-3"></b-form-select>
        </b-form-group> -->

        <div v-if="colourOptions">
          <label for="">Цвет группы</label><br>
          <div class="d-flex justify-content-between">
            <a 
              v-for="colour in colourOptions" :key="colour.value"
              class="btn btn-link btn-colour mr-2 no-shadow p-0"
              :class="{ 'btn-disabled': !isActiveColour(colour.value) }"
              href="#" role="button"
              @click.prevent="setActiveColour(colour.value)"
              :style="{ 'background': colour.value }">
              <img src="/static/images/icons/tick_white.svg" alt="" v-if="isActiveColour(colour.value)">
            </a>
          </div>
        </div>
        <br>

        <b-button type="submit" variant="primary" size="lg" block>
          Сохранить
        </b-button>
      </b-form>
    </validation-observer>
    <b-button type="submit" variant="light" size="lg" block @click="hideModal" class="mt-2">
      Отмена
    </b-button>
  </b-modal>
</template>

<script>
import { slugify } from '@/services/utils'
import { mapState } from 'vuex'

export default {
  props: {
    item: [Object]
  },
  data () {
    return {
      group: {
        title: '',
        slug: '',
        createdAt: '',
        isActive: true,
        colour: '',
        address: '',
        schedule: {
          monday: {
            isActive: false
          },
          tuesday: {
            isActive: false
          },
          wednesday: {
            isActive: false
          },
          thursday: {
            isActive: false
          },
          friday: {
            isActive: false
          },
          saturday: {
            isActive: false
          },
          sunday: {
            isActive: false
          }
        }
      }
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    isActiveColour (colour) {
      return this.group.colour === colour
    },
    setActiveColour (colour) {
      this.group.colour = colour
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid ? 'valid' : 'invalid'
      return valid
    },
    submit () {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return
      // }
      const { group } = this
      if (this.item) {
        this.$store.dispatch('UPDATE_GROUP', group).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      } else {
        this.group.slug = this.slug
        this.group.createdAt = this.$moment().format()
        this.$store.dispatch('CREATE_GROUP', { group }).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      }
    },
    resetModal () {
      this.group.title = ''
      this.group.createdAt = ''
      this.group.address = ''
      this.group.slug = ''
      this.group.isActive = true
      this.group.colour = ''
      this.group.schedule = {
        monday: {
          isActive: false
        },
        tuesday: {
          isActive: false
        },
        wednesday: {
          isActive: false
        },
        thursday: {
          isActive: false
        },
        friday: {
          isActive: false
        },
        saturday: {
          isActive: false
        },
        sunday: {
          isActive: false
        }
      }
    },
    showModal () {
      this.$refs['modal'].show()
    },
    hideModal () {
      this.$refs['modal'].hide()
    },
    toggleModal () {
      this.resetModal()
      if (this.item) {
        this.group.title = this.item.title
        this.group.address = this.item.address
        this.group.createdAt = this.item.createdAt
        this.group.isActive = this.item.isActive
        this.group.slug = this.item.slug
        this.group.schedule = this.item.schedule
        this.group.colour = this.item.colour
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapState({
      colourOptions: state => state.auth.colourOptions,
      statusOptions: state => state.auth.statusOptions
    }),
    slug () {
      return slugify(this.group.title)
    },
    title () {
      if (this.item) {
        return 'Редактировать группу'
      } else {
        return 'Новая группа'
      }
    },
    btnName () {
      if (this.item) {
        return 'Сохранить'
      } else {
        return 'Создать'
      }
    }
  },
  created () {
  }
}
</script>