import firebase from 'firebase/app'
const db = firebase.firestore()
const moment = require('moment')

const state = {
  groups: [],
  pendingStudents: [],
  students: [],
  journal: []
}

const actions = {
  CREATE_GROUP ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let data = payload.group
      var userEmail = firebase.auth().currentUser.email
      db.collection('users')
        .doc(userEmail)
        .collection('groups')
        .doc(String(data['slug']))
        .set(data)
        .then(snapshot => {
          resolve(snapshot)
        })
    })
  },
  GET_GROUPS ({ commit }) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      let ref = db.collection('users').doc(userEmail).collection('groups')
      let unsub = ref.onSnapshot(snapshot => {
        let groups = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          groups.push(log)
        })
        commit('SET_LOADED_GROUPS', groups)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  },
  UPDATE_GROUP ({ commit }, payload) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      let data = payload
      db.collection('users')
        .doc(userEmail)
        .collection('groups')
        .doc(data.slug)
        .update({
          title: data.title,
          createdAt: data.createdAt,
          address: data.address,
          slug: data.slug,
          isActive: data.isActive,
          schedule: data.schedule,
          colour: data.colour
        })
        .then(snapshot => {
          resolve(snapshot)
        })
    })
  },
  CREATE_STUDENT ({ commit }, payload) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      let data = payload.student

      db.collection('students')
        .add(data)
        .then(snapshot => {
          db.collection('users').doc(userEmail).update({
            coach: firebase.auth().currentUser.uid,
            students: firebase.firestore.FieldValue.arrayUnion(snapshot.id)
          }).then(snapshot => {
            resolve(snapshot)
          })
          db.collection('students').doc(snapshot.id).update({
            id: snapshot.id,
            coach: firebase.auth().currentUser.email
          }).then(snapshot => {
            resolve(snapshot)
          })
        })
    })
  },
  // GET_STUDENTS ({ commit }) {
  //   var userEmail = firebase.auth().currentUser.email
  //   return new Promise((resolve, reject) => {
  //     let ref = db.collection('users').doc(userEmail).collection('students')
  //     let unsub = ref.onSnapshot(snapshot => {
  //       let students = []
  //       snapshot.forEach((doc) => {
  //         let log = {
  //           'id': doc.id,
  //           ...doc.data()
  //         }
  //         students.push(log)
  //       })
  //       commit('SET_LOADED_STUDENTS', students)
  //     }, (error) => {
  //       reject(error)
  //     })
  //     resolve(unsub)
  //   })
  // },
  GET_COACH_STUDENTS ({ commit }, students) {
    return new Promise((resolve, reject) => {
      if (students) {
        let allStudents = []
        students.forEach((student) => {
          db.collection('students').doc(student).get().then((doc) => {
            if (doc) {
              allStudents.push(doc.data())
              commit('SET_LOADED_STUDENTS', allStudents)
            }
          })
        })

        resolve()
      }
    })
  },
  GET_PENDING_STUDENTS ({ commit }, students) {
    return new Promise((resolve, reject) => {
      if (students) {
        let allStudents = []
        students.forEach((student) => {
          db.collection('students').doc(student).get().then((doc) => {
            if (doc) {
              allStudents.push(doc.data())
              commit('SET_PENDING_STUDENTS', allStudents)
            }
          })
        })
      }
      resolve()
    })
  },
  GET_STUDENTS_BY_GROUP ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.students) {
        let allStudents = []
        payload.students.forEach((student) => {
          db.collection('students').doc(student).get().then((doc) => {
            if (doc && doc.data().group === payload.group) {
              allStudents.push(doc.data())
              commit('SET_LOADED_STUDENTS', allStudents)
            }
          })
        })

        resolve()
      }
    })
  },
  UPDATE_STUDENT ({ commit }, payload) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      let data = payload
      db.collection('students')
        .doc(data.id)
        .update(data)
        .then(snapshot => {
          db.collection('users').doc(userEmail).update({
            students: firebase.firestore.FieldValue.arrayUnion(data.id)
          }).then(snapshot => {
            resolve(snapshot)
          })
        })
    })
  },
  REMOVE_PENDING_STUDENT_FROM_COACH ({ commit }, studentId) {
    console.log('remove')
    console.log(studentId)
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      db.collection('users').doc(userEmail).update({
        pendingStudents: firebase.firestore.FieldValue.arrayRemove(studentId)
      }).then(snapshot => {
        resolve(snapshot)
      })
    })
  },
  REMOVE_FROM_JOURNAL ({ commit }, payload) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      let data = payload
      let day = moment(data.day).format('DD')
      let month = moment(data.day).format('MM')
      db.collection('users')
        .doc(userEmail)
        .collection('journal')
        .doc(moment(data.day).format('YYYY'))
        .set({
          [month]: {
            [day]: {
              students: firebase.firestore.FieldValue.arrayRemove(data.student.id)
            }
          }
        }, { merge: true })
        .then(snapshot => {
          resolve(snapshot)
        })
    })
  },
  ADD_TO_JOURNAL ({ commit }, payload) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      let data = payload
      let day = moment(data.day).format('DD')
      let month = moment(data.day).format('MM')
      db.collection('users')
        .doc(userEmail)
        .collection('journal')
        .doc(moment(data.day).format('YYYY'))
        .set({
          [month]: {
            [day]: {
              students: firebase.firestore.FieldValue.arrayUnion(data.student.id)
            }
          }
        }, { merge: true })
        .then(snapshot => {
          resolve(snapshot)
        }, (error) => {
          console.log(error)
          reject(error)
        })
      // db.collection('users')
      //   .doc(userEmail)
      //   .collection('journal')
      //   .doc(moment(data.day).format('YYYY'))
      //   .collection('months')
      //   .doc((data.day).format('MM'))
      //   .set({
      //     month: {
      //       students: firebase.firestore.FieldValue.arrayUnion(data.student.id)
      //     }
      //   }, { merge: true })
      //   .then(snapshot => {
      //     resolve(snapshot)
      //   })

      // db.collection('users')
      //   .doc(userEmail)
      //   .collection('journal')
      //   .doc(moment(data.day).format('YYYY'))
      //   .collection('months')
      //   .doc(moment(data.day).format('MM'))
      //   .collection('days')
      //   .doc(moment(data.day).format('DD'))
      //   .update({
      //     students: firebase.firestore.FieldValue.arrayUnion(data.student.id)
      //   })
      //   .then(snapshot => {
      //     resolve(snapshot)
      //   })
    })
  },
  GET_JOURNAL ({ commit }, payload) {
    var userEmail = firebase.auth().currentUser.email
    return new Promise((resolve, reject) => {
      db.collection('users').doc(userEmail).collection('journal').doc(payload).get().then((doc) => {
        if (doc) {
          commit('SET_LOADED_JOURNAL', doc.data())
        }
      })
    })
  }
}

const getters = {
}

const mutations = {
  SET_LOADED_GROUPS (state, payload) {
    state.groups = payload
  },
  SET_LOADED_STUDENTS (state, payload) {
    state.students = payload
  },
  SET_LOADED_JOURNAL (state, payload) {
    state.journal = payload
  },
  SET_PENDING_STUDENTS (state, payload) {
    state.pendingStudents = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
