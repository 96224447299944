<template>
  <div>
    <h1>Account</h1>
    <hr class="space space-sm">

    <div class="row">
      <div class="col-md-6">
        <h3>Change your password</h3>
        <br>

        <b-form @submit.prevent="submit">
          <b-form-group label="Enter original password:">
            <b-form-input id="password"
                          type="password"
                          v-model="password.old_password"
                          required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="New password:">
            <b-form-input id="password-new"
                          type="password"
                          v-model="password.password"
                          required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Re-enter new password:">
            <b-form-input id="password-repeat"
                          type="password"
                          v-model="passwordConfirmation"
                          required>
            </b-form-input>
          </b-form-group>
          <div class="error" v-if="hasError">
            {{ this.errorInfo }}
          </div>
          <p v-if="password.password && !passwordsMatch"><span class="text-danger help-block">The password confirmation does not match.</span></p>
          <p v-if="submitted"><span class="text-success help-block">Password changed.</span></p>
          <b-button type="submit" variant="primary">Change password</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      password: {
        old_password: null,
        password: null
      },
      submitted: false,
      hasError: false,
      errorInfo: null,
      passwordConfirmation: null,
      passwordsMatch: false
    }
  },
  computed: {
  },
  methods: {
    submit () {
      const { password } = this
      this.$store.dispatch('UPDATE_PASSWORD', { password }).then((response) => {
        // TODO: feedback
        this.submitted = true
        this.resetForm()
      }, error => {
        console.log(error)
      })
    },
    resetForm () {
      this.password.old_password = null
      this.password.password = null
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
  },
  watch: {
    'passwordConfirmation' () {
      if (this.password.password === this.passwordConfirmation) {
        this.passwordsMatch = true
      } else {
        this.passwordsMatch = false
      }
    }
  }
}
</script>