import firebase from 'firebase/app'
const db = firebase.firestore()

const state = {
  coaches: []
}

const actions = {
  GET_COACHES ({ commit }) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('users').where('isCoach', '==', true)
      let unsub = ref.onSnapshot(snapshot => {
        let coaches = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          coaches.push(log)
        })
        commit('SET_LOADED_COACHES', coaches)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  }
}

const getters = {
}

const mutations = {
  SET_LOADED_COACHES (state, payload) {
    state.coaches = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
