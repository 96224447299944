<template>
  <b-modal 
    id="modal-1"
    ref="modal"
    :title="title"
    @show="toggleModal"
    @hidden="resetModal"
    @ok="handleOk"
    ok-title="Добавить страну"
    cancel-title="Отмена"
    cancel-variant="light"
    footer-class="justify-content-between"
  >
    <pre>{{ country }}</pre>
    <b-form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group label="Название страны" label-for="title">
        <b-form-input
          id="title"
          v-model="country.title"
          required
          placeholder=""
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Статус" label-for="status">
        <b-form-select v-model="country.isActive" :options="statusOptions" class="mb-3"></b-form-select>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { slugify } from '@/services/utils'

export default {
  props: {
    item: [Object]
  },
  data () {
    return {
      country: {
        title: '',
        slug: '',
        createdAt: '',
        isActive: true
      }
    }
  },
  methods: {
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid ? 'valid' : 'invalid'
      return valid
    },
    handleSubmit () {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return
      // }
      const { country } = this
      if (this.item) {
        this.$store.dispatch('UPDATE_CITY', country).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      } else {
        this.country.slug = this.slug
        this.country.createdAt = this.$moment().format()
        this.$store.dispatch('CREATE_COUNTRY', { country }).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      }
    },
    resetModal () {
      this.country.title = ''
      this.country.createdAt = ''
      this.country.slug = ''
      this.country.isActive = true
    },
    showModal () {
      this.$refs['modal'].show()
    },
    hideModal () {
      this.$refs['modal'].hide()
    },
    toggleModal () {
      this.resetModal()
      if (this.item) {
        this.country.title = this.item.title
        this.country.createdAt = this.item.createdAt
        this.country.isActive = this.item.isActive
        this.country.slug = this.item.slug
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapState({
      statusOptions: state => state.auth.statusOptions
    }),
    slug () {
      return slugify(this.country.title)
    },
    title () {
      if (this.item) {
        return 'Редактировать страну'
      } else {
        return 'Добавить страну'
      }
    }
  },
  created () {
  }
}
</script>