<template>
  <div>
    <profile-coach v-if="user.isCoach"></profile-coach>
    <profile-student v-if="user.isStudent"></profile-student>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProfileCoach from '../Coach/Profile'
import ProfileStudent from '../Student/Profile'

export default {
  components: {
    profileCoach: ProfileCoach,
    profileStudent: ProfileStudent
  },
  data () {
    return {
      role: null,
      roleOptions: [
        { value: true, text: 'Ученик' },
        { value: false, text: 'Тренер' }
      ]
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
  }
}
</script>