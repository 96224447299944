<template>
  <div>
    <div class="form-group" :class="{ 'is-active': isActive, 'is-active': value, 'input-group mb-3': prepend }">
      <div class="input-group-prepend" v-if="prepend"><div class="input-group-text">{{ prepend }}</div></div>
      <label class="custom-label control-label">{{label}}</label>
      <input class="form-control border-input"
        v-if="$props.type=='text'"
        v-bind="$props"
        :value="value"
        @click="toggleFocus()"
        @blur ="toggleFocus()"
        @input="$emit('input',$event.target.value)">
      <textarea rows="6" class="form-control border-input"
        v-if="$props.type=='textarea'"
        v-bind="$props"
        :value="value"
        @click="toggleFocus()"
        @blur ="toggleFocus()"
        @input="$emit('input',$event.target.value)"></textarea>
      <slot v-if="!$props.prepend"></slot>
      <span v-if="!prepend" class="help-block">{{ help }}</span>
    </div>
    <div class="help-block mt-3 mb-3" v-if="prepend">{{ help }}</div>
  </div>
</template>
<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'text'
      },
      label: String,
      name: String,
      disabled: Boolean,
      placeholder: String,
      help: String,
      prepend: String,
      value: [String, Number, Array]
    },
    data () {
      return {
        isActive: false
      }
    },
    methods: {
      toggleFocus () {
        this.isActive = !this.isActive
      }
    }
  }
</script>
