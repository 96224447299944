<template>
  <div>
    <h2>История</h2>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>