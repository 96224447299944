<template>
  <b-modal 
    id="modal-1"
    ref="modal"
    :title="title"
    @show="toggleModal"
    @hidden="resetModal"
    @ok="handleOk"
    hide-footer
    :ok-title="btnTitle"
    cancel-title="Отмена"
    cancel-variant="light"
    footer-class="justify-content-between"
  >
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form ref="form" @submit.stop.prevent="handleSubmit(submit)">
        <div class="d-flex">
          <validation-provider
            name="Фамилия"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Фамилия" label-for="lastName" class="mr-3 w-100">
              <b-form-input id="lastName"
                type="text"
                v-model="student.lastName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Имя"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Имя" label-for="firstName" class="mr-3 w-100">
              <b-form-input id="firstName"
                type="text"
                v-model="student.firstName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Отчество"
            rules="alpha_spaces|required"
            v-slot="validationContext"
            class="mr-3"
          >
            <b-form-group label="Отчество" label-for="middleName" class="w-100">
              <b-form-input id="middleName"
                type="text"
                v-model="student.middleName"
                :state="getValidationState(validationContext)">
              </b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>

        <div class="d-flex">
          <validation-provider
            name="Группа"
            rules="required"
            v-slot="validationContext"
            class="w-100 mr-2"
          >
            <b-form-group label="Группа" label-for="group">
              <b-form-select v-model="student.group" :state="getValidationState(validationContext)">
                <option :value="null" disabled>Выберите группу</option>
                <option v-for="option in groupOptions" :value="option.value" :key="option.value">{{ option.text }}</option>
              </b-form-select>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Пол" label-for="gender" class="w-100 ml-2">
            <b-form-radio-group
              id="gender"
              v-model="student.gender"
              :options="genderOptions"
              buttons
              button-variant="light"
              name="radios-btn-default"
            ></b-form-radio-group>
          </b-form-group>
        </div>

        <b-form-group label="Основа обучения" label-for="group">
          <b-form-select v-model="student.isPaid" :options="studentPaymentTypeOptions"></b-form-select>
        </b-form-group>

        <!-- <b-form-group label="Статус" label-for="status">
          <b-form-select v-model="student.isActive" :options="statusOptions" class="mb-3"></b-form-select>
        </b-form-group> -->
        <b-button type="submit" variant="primary" size="lg" block>
          Сохранить
        </b-button>
      </b-form>
    </validation-observer>
    <b-button type="submit" variant="light" size="lg" block @click="hideModal" class="mt-2">
      Отмена
    </b-button>
  </b-modal>
</template>

<script>
import { slugify } from '@/services/utils'
import { mapState } from 'vuex'

export default {
  props: {
    item: [Object],
    addToGroup: true,
    testItem: Boolean
  },
  data () {
    return {
      student: {
        firstName: null,
        lastName: null,
        gender: 'male',
        middleName: null,
        createdAt: null,
        group: null,
        isActive: true,
        isPaid: true,
        email: null,
        id: null,
        isPendingCoach: false
      }
    }
  },
  computed: {
    ...mapState({
      genderOptions: state => state.auth.genderOptions,
      statusOptions: state => state.auth.statusOptions,
      studentPaymentTypeOptions: state => state.auth.studentPaymentType,
      groups: state => state.coaches.groups,
      user: state => state.auth.user
    }),
    groupOptions () {
      return Object.keys(this.groups).map(group => {
        return { value: this.groups[group].slug, text: this.groups[group].title }
      }).sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0))
    },
    slug () {
      return slugify(this.group.title)
    },
    title () {
      if (this.item) {
        return 'Редактирование ученика'
      } else {
        return 'Новый ученик'
      }
    },
    btnTitle () {
      if (this.item) {
        return 'Сохранить'
      } else {
        return 'Создать'
      }
    }
  },
  methods: {
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    submit () {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return
      // }
      const { student } = this

      if (this.item && !this.student.isPendingCoach) {
        this.$store.dispatch('UPDATE_STUDENT', student).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      } else if (this.student.isPendingCoach) {
        this.student.isPendingCoach = false
        this.student.coach = this.user.email
        this.$store.dispatch('UPDATE_STUDENT', student).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
        this.$store.dispatch('REMOVE_PENDING_STUDENT_FROM_COACH', student.id)
      } else {
        this.student.createdAt = this.$moment().format()
        this.$store.dispatch('CREATE_STUDENT', { student }).then((response) => {
          this.$emit('update')
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
      }
    },
    resetModal () {
      this.student.firstName = null
      this.student.lastName = null
      this.student.middleName = null
      this.student.createdAt = null
      this.student.group = null
      this.student.email = null
      this.student.isActive = true
      this.student.id = null
      this.student.gender = 'male'
      this.student.isPaid = true
    },
    showModal () {
      this.$refs['modal'].show()
    },
    hideModal () {
      this.$refs['modal'].hide()
    },
    toggleModal () {
      this.resetModal()
      if (this.item) {
        this.student.firstName = this.item.firstName
        this.student.lastName = this.item.lastName
        this.student.middleName = this.item.middleName ? this.item.middleName : null
        this.student.group = this.item.group ? this.item.group : null
        this.student.createdAt = this.item.createdAt ? this.item.createdAt : this.$moment().format()
        this.student.isActive = this.item.isActive ? this.item.isActive : true
        this.student.email = this.item.email ? this.item.email : null
        this.student.id = this.item.id ? this.item.id : null
        this.student.gender = this.item.gender ? this.item.gender : 'male'
        if (typeof this.item.isPaid === 'boolean') {
          this.student.isPaid = this.item.isPaid
        } else {
          this.student.isPaid = true
        }
        this.student.isPendingCoach = this.item.isPendingCoach ? this.item.isPendingCoach : false
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
  }
}
</script>