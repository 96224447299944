<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <h2>График тренировок</h2>
      </div>
      <div class="col-md-4 d-flex justify-content-center align-items-center" v-if="generatedWeek">
        <a class="btn btn-link no-shadow px-3" href="#" @click.prevent="displayPrevWeek()" :class="{ 'disabled': isPrevOutOfScope() }"><img src="/static/images/icons/chevron_left.svg" alt=""></a>
        <div class="lead text-secondary">{{ generatedWeek[0] | moment('D MMM') }} - {{ generatedWeek[6] | moment('D MMM') }}</div>
        <a class="btn btn-link no-shadow px-3" href="#" @click.prevent="displayNextWeek()" :class="{ 'disabled': isNextOutOfScope() }"><img src="/static/images/icons/chevron_right.svg" alt=""></a>
      </div>
      <div class="col-md-4 text-md-right">
        <a href="#" @click.prevent="openEditModal(false)" class="btn btn-light">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
          Добавить группу
        </a>
      </div>
    </div>
    <br>

    <b-overlay :show="show" rounded="sm">
      <div class="row mx-0" v-if="generatedWeek && generatedWeek.length > 0">
        <div class="col py-3 border"
          v-for="(day, index) in generatedWeek"
          :key="index"
          :class="{ 'rounded-left': index == 0, 'rounded-right': index == 6, 'no-border-right': index !== 6, 'text-muted': today > dateToNumber(day) }">
          <div class="text-center">
            <p class="text-uppercase text-grey mb-2">{{ day | moment('dd') }}</p>
            <p class="lead" :class="{ 'bg-danger text-white rounded': isToday(day) }">{{ day | moment('D') }}</p>
          </div>

          <div v-for="group in groups" :key="group.slug">
            <div class="position-relative pl-3 mb-3 cursor-pointer" @click.prevent="openEditModal(group)" v-if="mapScheduleToDay(day, group).isActive">
              <div class="calendar-item-colour" :style="{ 'background': group.colour ? group.colour : '#B5AAB8' }"></div>
              <p class="mb-1 lh1"><strong>{{ group.title }}</strong></p>
              <p class="mb-0 lh1" v-if="mapScheduleToDay(day, group).from && mapScheduleToDay(day, group).to"><span class="normal">{{ mapScheduleToDay(day, group).from.slice(0, 5) }} - {{ mapScheduleToDay(day, group).to.slice(0, 5) }}</span></p>
              <p class="mb-0 lh1 text-muted small" v-else>Время не указано</p>
            </div>
          </div>

          <div class="text-muted text-center lh1" v-if="dayHasSessions(day, groups) && dayHasSessions(day, groups).length == 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-1 feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
            <p class="text-muted text-center mt-2">Тренировок нет</p>
          </div>
        </div>
      </div>
      <div v-else class="border rounded p-3 py-5 text-center text-muted">
        <h5 class="text-muted">Тренировок нет</h5>
        <p class="mb-0">У вас еще нет групп, поэтому график тренировок пуст.<br>
        Как только вы добавите группы в разделе <router-link :to="{ name: 'students' }">Ученики</router-link>, они отобразятся здесь</p>
      </div>
    </b-overlay>
    <group-add :item="editGroup" ref="modal" @update="update"></group-add>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GroupAdd from './GroupAddModal'

export default {
  components: {
    groupAdd: GroupAdd
  },
  data () {
    return {
      show: true,
      generatedWeek: null,
      timeTracker: this.$moment(),
      today: this.$moment().date(),
      editGroup: null
    }
  },
  computed: {
    ...mapState({
      groups: state => state.coaches.groups
    })
  },
  methods: {
    mapScheduleToDay (day, schedule) {
      let weekDay = this.$moment(day).locale('en').format('dddd').toLowerCase()
      let daySchedule = schedule.schedule[weekDay]
      return daySchedule
    },
    dayHasSessions (day, groups) {
      let days = []
      groups.forEach((group) => {
        if (this.mapScheduleToDay(day, group).isActive) {
          days.push(group)
        }
      })
      return days
    },
    openEditModal (item) {
      if (item) {
        this.editGroup = item
        setTimeout(() => {
          this.$refs.modal.showModal()
        }, 100)
      } else {
        this.editGroup = null
        setTimeout(() => {
          this.$refs.modal.showModal()
        }, 100)
      }
    },
    update () {
      this.$store.dispatch('GET_GROUPS')
      this.$refs.modal.hideModal()
    },
    getStartEndOfWeek (e) {
      if (e === 0) {
        this.timeTracker = this.$moment()
      } else {
        this.timeTracker.add(e, 'weeks')
      }

      // Find start and end of week
      var startOfWeek = this.timeTracker.clone().startOf('isoWeek')
      var endOfWeek = this.timeTracker.clone().endOf('isoWeek')

      var days = []
      var day = startOfWeek

      while (day.isSameOrBefore(endOfWeek)) {
        days.push(day.toDate())
        day = day.add(1, 'days')
      }

      setTimeout(() => {
        this.show = false
      }, 100)
      return days
    },
    displayPrevWeek () {
      this.show = true
      this.generatedWeek = this.getStartEndOfWeek(-1)
    },
    displayNextWeek () {
      this.show = true
      this.generatedWeek = this.getStartEndOfWeek(1)
    },
    displayCurrentWeek () {
      this.show = true
      this.generatedWeek = this.getStartEndOfWeek()
    },
    dateToNumber (day) {
      return this.$moment(day).format('D')
    },
    isToday (day) {
      return this.$moment(day).isSame(new Date(), 'day')
    },
    isNextOutOfScope () {
      let thisWeekNumber = this.$moment().week()
      let selectedWeekNumber = this.$moment(this.generatedWeek[0]).week()
      let diff = selectedWeekNumber - thisWeekNumber
      // let isSameWeek = this.$moment(this.generatedWeek[0]).isSame(new Date(), 'week')
      if (diff > 0) {
        return true
      }
    },
    isPrevOutOfScope () {
      let thisWeekNumber = this.$moment().week()
      let selectedWeekNumber = this.$moment(this.generatedWeek[0]).week()
      let diff = selectedWeekNumber - thisWeekNumber
      // let isSameWeek = this.$moment(this.generatedWeek[0]).isSame(new Date(), 'week')
      if (diff < 0) {
        return true
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.displayCurrentWeek()

    setTimeout(() => {
      this.show = false
    }, 2000)
  }
}
</script>