<template>
  <div id="app" class="d-flex flex-column h-100">
    <main role="main" class="flex-shrink-0">
      <app-header></app-header>
      <router-view></router-view>
    </main>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from './components/Layout/Header.vue'
import Footer from './components/Layout/Footer.vue'

export default {
  name: 'app',

  components: {
    'app-header': Header,
    'app-footer': Footer
  }
}
</script>

<style lang="scss"></style>
