import firebase from 'firebase/app'
const db = firebase.firestore()

const state = {
  applicants: null
}

const actions = {
  GET_CUP_APPLICANTS ({ commit }) {
    return new Promise((resolve, reject) => {
      let ref = db.collection('fighters2021')
      let unsub = ref.onSnapshot(snapshot => {
        let applicants = []
        snapshot.forEach((doc) => {
          let log = {
            'id': doc.id,
            ...doc.data()
          }
          applicants.push(log)
        })
        commit('SET_CUP_APPLICANTS', applicants)
      }, (error) => {
        reject(error)
      })
      resolve(unsub)
    })
  }
}

const getters = {
}

const mutations = {
  SET_CUP_APPLICANTS (state, payload) {
    state.applicants = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
